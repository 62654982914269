import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { AccountProvider } from "../context";
import { styled } from "styled-components";

import CampaignsTable from "../components/Campaigns/CampaignsTable";
import RateCard from "../components/Campaigns/RateCard";
import CampaignDescription from "../components/Campaigns/CampaignDescription";
import Loader from "../components/Loader";
import NoAnyCampaignsBlock from "../components/Campaigns/NoAnyCampaignsBlock";
import CheckExtensionVersion from "../components/CheckExtensionVersion";

import {
  getCampaignsStatistic,
  resetTempCampaign,
  updateCampaign,
  createCampaign,
  updateCampaignStatus,
  getCampaign,
} from "../services/campaigns";
import { sendMessageToExtension } from "../utils/postToExtension";
import {
  getDataFromLocalStorage,
  getObjectFromLocalStorage,
  removeDataFromLocalStorage,
  saveObjectToLocalStorage,
} from "../api/localStorage";
import { loadUserData } from "../services/user";
import { updateFirstTimeCampaignOnboardingUserList } from "../utils/utils";
import getUserFromLocalStorage from "../utils/getUserFromLocalStorage";

const Campaigns = () => {
  const userInfo = getUserFromLocalStorage();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isFinishedCreation = searchParams.get("isFinishedCreation");
  const isNeedToLaunch = searchParams.get("isNeedToLaunch");
  const crm = searchParams.get("crm");
  const campaignIdFromUrlParams = searchParams.get("campaignId");

  const [campaignStatisticList, setCampaignStatisticList] = useState({});
  const [selectedCampaignsFilters, setSelectedCampaignsFilters] =
    useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(
    location.state?.campaignToSelect || null
  );
  const [isNeedToCreateCampaign, setIsNeedToCreateCampaign] = useState(
    isFinishedCreation === "true"
  );
  const [isCreatingCampaign, setIsCreatingCampaign] = useState(
    isFinishedCreation === "true"
  );

  const [errorMessage, setErrorMessage] = useState(null);
  const [isStatisticLoading, setIsStatisticLoading] = useState(true);

  const [isTableFullScreen, setIsTableFullScreen] = useState(false);

  const [isLoadingUserData, setIsLoadingUserData] = useState(true);

  const [isCrmPage, setIsCrmPage] = useState(null);
  const [campaignBeingPrepared, setCampaignBeingPrepared] = useOutletContext();

  const selectCampaign = (campaign, campaignsFilters) => {
    setIsNeedToCreateCampaign(false);
    setSelectedCampaign(campaign);
    setSelectedCampaignsFilters(campaignsFilters || null);
  };

  const onCloseOverview = async () => {
    if (!campaignStatisticList?.allCampaignsCount) {
      setIsStatisticLoading(true);
      await fetchCampaignsStatistic();
    }
    setIsNeedToCreateCampaign(false);
    setIsCreatingCampaign(false);
    setSelectedCampaign(null);
    setCampaignBeingPrepared(null);
    removeDataFromLocalStorage("campaignToMinimize");
  };

  const onScroll = (e) => {
    setIsTableFullScreen(true);

    if (e.deltaY < 0) {
      if (e.target.scrollTop === 0) {
        setIsTableFullScreen(false);
      }
    }
  };

  const attemptToCreateCampaign = () => {
    sendMessageToExtension({
      message: "attempt-create-campaign",
    });
  };

  const fetchCampaignsStatistic = async () => {
    const data = await getCampaignsStatistic();
    if (data.success !== undefined && !data.success) {
      setErrorMessage(data.message);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: data.message,
        },
      });
      setIsStatisticLoading(false);
      return;
    }
    setErrorMessage(null);
    setCampaignStatisticList(data.result);
    setIsStatisticLoading(false);
  };

  const finishCreateCampaign = async () => {
    const isCampaignChanged = getDataFromLocalStorage("isCampaignChanged");
    const currentDraftCampaign = getObjectFromLocalStorage(
      "currentDraftCampaign"
    );

    let campaignId;

    if (currentDraftCampaign) {
      campaignId = currentDraftCampaign.id;
      setSelectedCampaign(currentDraftCampaign);
    }

    if (!currentDraftCampaign || isCampaignChanged === "true") {
      const campaign = await fetchCreateTempCampaign({
        isNeedToStartCampaign: true,
        isNeedToResetTempCampaign: true,
      });
      if (!campaign) {
        return;
      }
      setSelectedCampaign(campaign);
      campaignId = campaign.id;
    } else {
      const result = await updateCampaignStatus({
        campaignId: campaignId,
        status: "Start",
      });
      if (result.success !== undefined && !result.success) {
        console.log("create campaign error", result.message);
        removeDataFromLocalStorage("currentDraftCampaign");
        sendMessageToExtension({
          message: "create-campaign-error",
        });
        return;
      }
    }

    removeDataFromLocalStorage("currentDraftCampaign");
    removeDataFromLocalStorage("currentTempCampaign");
    removeDataFromLocalStorage("tempCampaignId");
    removeDataFromLocalStorage("isCampaignChanged");

    await resetTempCampaign(crm);
    setIsCreatingCampaign(false);
  };

  const generateAll = async () => {
    const isCampaignChanged = getDataFromLocalStorage("isCampaignChanged");
    const currentDraftCampaign = getObjectFromLocalStorage(
      "currentDraftCampaign"
    );

    if (currentDraftCampaign) {
      setSelectedCampaign(currentDraftCampaign);
    }

    if (!currentDraftCampaign || isCampaignChanged === "true") {
      const campaign = await fetchCreateTempCampaign({
        isNeedToGenerateAllMessagePreviews: true,
        isNeedToResetTempCampaign: true,
      });
      if (!campaign) {
        return;
      }
      setSelectedCampaign(campaign);
    }

    removeDataFromLocalStorage("currentDraftCampaign");
    removeDataFromLocalStorage("currentTempCampaign");
    removeDataFromLocalStorage("tempCampaignId");
    removeDataFromLocalStorage("isCampaignChanged");

    await resetTempCampaign(crm);
    setIsCreatingCampaign(false);
  };

  const fetchCreateTempCampaign = async (data) => {
    let currentDraftCampaign = getObjectFromLocalStorage(
      "currentDraftCampaign"
    );
    const isCampaignChanged = getDataFromLocalStorage("isCampaignChanged");

    if (currentDraftCampaign && isCampaignChanged === "false") {
      return currentDraftCampaign;
    }

    const createCampaignFields = getObjectFromLocalStorage("preparedFields");
    const tempCampaignId = getDataFromLocalStorage("tempCampaignId");

    const result =
      isCampaignChanged === "true"
        ? await updateCampaign({
            campaign: createCampaignFields,
            campaignId: currentDraftCampaign.id,
            isNeedToStartCampaign: data?.isNeedToStartCampaign,
            isNeedToGenerateAllMessagePreviews:
              data?.isNeedToGenerateAllMessagePreviews,
          })
        : await createCampaign({
            campaign: { ...createCampaignFields, tempCampaignId },
            isNeedToStartCampaign: data?.isNeedToStartCampaign,
            isNeedToGenerateAllMessagePreviews:
              data?.isNeedToGenerateAllMessagePreviews,
          });

    if (result.success !== undefined && !result.success) {
      console.log("create temp campaign error", result.message);
      removeDataFromLocalStorage("currentDraftCampaign", true);

      sendMessageToExtension({
        message: "create-campaign-error",
      });
      return;
    }

    saveObjectToLocalStorage(
      "currentDraftCampaign",
      result.result.campaign,
      true
    );
    return result.result.campaign;
  };

  const postMessageEventHandler = (event) => {
    if (typeof event.data.isCrm !== "boolean") {
      return;
    }

    window.removeEventListener("message", postMessageEventHandler);

    setIsCrmPage(event.data.isCrm);

    if (
      event.data.isCrm &&
      selectedCampaign?.isNeedToGenerateMessagePreviews &&
      !campaignBeingPrepared
    ) {
      saveObjectToLocalStorage("campaignToMinimize", selectedCampaign);
      setCampaignBeingPrepared(selectedCampaign);
    }
  };

  useEffect(() => {
    loadUserData(null, (res) => {
      setIsLoadingUserData(false);

      if (res?.result?.postSignUpOnboarding?.completed !== false) {
        fetchCampaignsStatistic();

        if (userInfo?.hasCreatedCampaign) {
          updateFirstTimeCampaignOnboardingUserList({
            userId: userInfo?.id,
            initializeWithDefaultNames: true,
          });
        }

        window.addEventListener("message", postMessageEventHandler);
        sendMessageToExtension({
          message: "minimize-campaign-prep",
          data: { checkIsCrm: true },
        });
      }
    });
  }, []);

  useEffect(() => {
    if (!isNeedToCreateCampaign) {
      return;
    }

    const currentTempCampaign = getDataFromLocalStorage("currentTempCampaign");
    setSelectedCampaign(currentTempCampaign);

    if (isNeedToLaunch === "true") {
      finishCreateCampaign();
    } else {
      generateAll();
    }
  }, [isNeedToCreateCampaign]);

  useEffect(() => {
    if (!campaignIdFromUrlParams) {
      return;
    }

    const campaignToSelect = getObjectFromLocalStorage("campaignToSelect");
    if (campaignToSelect?.id === campaignIdFromUrlParams) {
      removeDataFromLocalStorage("campaignToSelect");
      setSelectedCampaign(campaignToSelect);
    }
  }, [campaignIdFromUrlParams]);

  const fetchCampaign = useCallback(
    async (campaignId) => {
      const data = await getCampaign(campaignId);

      if (data.success !== undefined && !data.success) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: "Something went wrong while trying to fetch campaign",
          },
        });
        return;
      }

      setIsNeedToCreateCampaign(false);
      setSelectedCampaign(data.result.campaign);
      setIsCreatingCampaign(false);
      setCampaignBeingPrepared(null);
    },
    [setCampaignBeingPrepared]
  );

  const fetchCampaignData = useCallback(async () => {
    const campaignIdReadyForLaunch = searchParams.get("campaignId");

    if (campaignIdReadyForLaunch) {
      await fetchCampaign(campaignIdReadyForLaunch);
    }
  }, [fetchCampaign]);

  useEffect(() => {
    fetchCampaignData();
  }, [fetchCampaignData]);

  if (isLoadingUserData) {
    return (
      <AccountProvider>
        <CheckExtensionVersion />
      </AccountProvider>
    );
  }

  const handleSetCampaignBeingPrepared = (campaign) => {
    if (isCrmPage !== false) {
      if (campaign) {
        saveObjectToLocalStorage("campaignToMinimize", campaign);
      } else {
        removeDataFromLocalStorage("campaignToMinimize");
      }
      setCampaignBeingPrepared(campaign);
    }
  };

  return (
    <>
      {selectedCampaign !== null || isCreatingCampaign ? (
        <CampaignDescription
          campaignId={selectedCampaign?._id || selectedCampaign?.id}
          isCreatingCampaign={isCreatingCampaign}
          onClose={onCloseOverview}
          setCampaignBeingPrepared={handleSetCampaignBeingPrepared}
        />
      ) : (
        <Layout
          $isNoAnyCampaigns={
            !isStatisticLoading && !campaignStatisticList?.allCampaignsCount
          }
        >
          <Content id="table_layout">
            {isStatisticLoading ? (
              <LoaderWrapper $height={"calc(100vh - 70px - 40px)"}>
                <Loader parentSize={true} />
              </LoaderWrapper>
            ) : !campaignStatisticList?.allCampaignsCount ? (
              <>
                <NoAnyCampaignsBlock
                  attemptToCreateCampaign={attemptToCreateCampaign}
                />
              </>
            ) : (
              <>
                <HeaderBlock>
                  <Title>Campaigns</Title>
                  <CreateCampaignBtn onClick={attemptToCreateCampaign}>
                    Create a Campaign
                  </CreateCampaignBtn>
                </HeaderBlock>
                {!errorMessage && (
                  <CardContainer>
                    <RateCard
                      title="Most successful campaign"
                      text={campaignStatisticList?.mostCampaign?.name || ""}
                      isLoading={isStatisticLoading}
                    />
                    <RateCard
                      title="Open rate"
                      currentRate={campaignStatisticList?.openRate || 0}
                      changeRate={campaignStatisticList?.changeOpenRate || 0}
                      isLoading={isStatisticLoading}
                    />
                    <RateCard
                      title="Engagement rate"
                      currentRate={campaignStatisticList?.engagementRate || 0}
                      changeRate={
                        campaignStatisticList?.changeEngagementRate || 0
                      }
                      isLoading={isStatisticLoading}
                    />
                  </CardContainer>
                )}
                <CampaignsTable
                  selectCampaign={selectCampaign}
                  tableHeight={
                    isTableFullScreen
                      ? "calc(100vh - 205px)"
                      : "calc(100vh - 425px)"
                  }
                  onScroll={onScroll}
                  isTableFullScreen={isTableFullScreen}
                  campaignsFilters={selectedCampaignsFilters}
                />
              </>
            )}
          </Content>
        </Layout>
      )}
      {/* </GlobalLayout> */}
      {/* </AccountProvider> */}
    </>
  );
};

export default Campaigns;

const CardContainer = styled.div`
  display: flex;
  margin-left: 40px;
`;

const Layout = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  height: calc(100vh - 70px);
  background-color: ${({ theme }) => theme.colors.background_color};
  padding: ${({ $isNoAnyCampaigns }) =>
    $isNoAnyCampaigns ? "0 0" : "20px 0 20px 30px"};
`;

const Content = styled.div`
  height: 100%;
  overflow: auto;
`;

const Title = styled.h3`
  font-size: 24px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.gray};
  font-family: "AlbertSansExtraBold", sans-serif;
`;

const HeaderBlock = styled.div`
  margin-left: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const CreateCampaignBtn = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.saturated_purple};
  height: 40px;
  width: 165px;
  font-family: "AlbertSansExtraBold", sans-serif;
  font-weight: 800;
  font-size: 12px;
  border-radius: 6px;
  border: none;
  margin-left: 20px;
`;

const LoaderWrapper = styled.div`
  height: ${({ $height }) => $height};
`;
