import React, { useEffect, useState } from "react";
import { AccountProvider } from "../context";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";

import {
  Account,
  Subscription,
  Prompts,
  EmailSignature,
  Samples,
  Unsubscribe,
  GreetingAndSignature,
  Usage,
  Users,
  Companies,
  Campaigns,
  MyTeam,
} from "../components/Settings/TabContent";
import Loader from "../components/Loader";
import SetEmailProvider from "../components/SetIntegrations/SetEmailProvider";
import SetSmsProvider from "../components/SetIntegrations/SetSmsProvider";
import SetAiModel from "../components/SetIntegrations/SetAiModel";
import SetCRM from "../components/SetIntegrations/SetCRM";
import Deliverability from "../components/Settings/TabContent/Deliverability";
import Scheduling from "../components/Settings/TabContent/Scheduling";
import SimpleConnect from "../components/Settings/TabContent/SimpleConnect";
import CheckExtensionVersion from "../components/CheckExtensionVersion";

import {
  disconnectIntegration,
  getIntegrations,
} from "../services/integrations";
import { loadUserData } from "../services/user";
import { sendMessageToExtension } from "../utils/postToExtension";
import getUserFromLocalStorage from "../utils/getUserFromLocalStorage";
import { CRMS, ENVs, USER_ROLES } from "../utils/constants";
import SuggestionPopup from "../components/SuggestionPopup";
import { fetchAutoConfigureLog } from "../services/queries";
import { getObjectFromLocalStorage } from "../api/localStorage";

const MULTI_LEVEL_TABS_LIST = ["integrations", "admin", "emailOptions"];

const Settings = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isOffice365Integration = searchParams.get("isOffice365");
  const isGSuiteIntegration = searchParams.get("isGSuite");
  const isHubspotIntegration = searchParams.get("isHubspot");
  const isCrmIntegration = searchParams.get("isCrm");
  const isAccessError = searchParams.get("isAccessError");
  const isFailedAutoConfigure = searchParams.get("isFailedAutoConfigure");
  const errorMessageForAutoConfigureLog = searchParams.get("errorMessage");
  const integrationDataToSet = location.state?.integrationData;
  const useEmailIntegrationDataToSet =
    integrationDataToSet?.type === "email" &&
    ["office365", "gsuite"].includes(integrationDataToSet.connectionKeys?.type);
  const useCrmIntegrationDataToSet =
    integrationDataToSet?.type === "crm" &&
    integrationDataToSet.connectionKeys?.type === CRMS.HUBSPOT;
  const isSubscriptionTab =
    String(searchParams.get("isSubscription")) === "true";
  const isEmailIntegrationSection =
    String(searchParams.get("isEmailInt")) === "true";
  const isSmsIntegrationSection =
    String(searchParams.get("isSmsInt")) === "true";

  const [isLoadingUserData, setIsLoadingUserData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuggestionPopupOpen, setIsSuggestionPopupOpen] = useState(
    Boolean(isAccessError)
  );

  const [filter, setFilter] = useState({});

  const userInfo = getUserFromLocalStorage();
  const companyInfo = getObjectFromLocalStorage("companyInfo");

  const isAdmin = userInfo?.role === USER_ROLES.ADMIN;
  const isCompanyAdmin = userInfo?.role === USER_ROLES.COMPANY_ADMIN;

  const SIDEBAR_TABS = {
    account: {
      title: "Account",
      component: () => <Account />,
    },
    integrations: {
      title: "Integrations",
      tabs: {
        crm: {
          title: "CRM",
          component: () => (
            <SetCRM
              onConnected={handleServiceConnected}
              connectionFields={integrations.crm}
              onDisconnect={handleDisconnectIntegration}
              {...(useCrmIntegrationDataToSet
                ? {
                    dataToSet: integrationDataToSet.connectionKeys,
                  }
                : null)}
            />
          ),
        },
        emailService: {
          title: "Email System",
          component: () => (
            <SetEmailProvider
              onConnected={handleServiceConnected}
              connectionFields={integrations.email}
              onReloadIntegrations={fetchIntegrations}
              onDisconnect={handleDisconnectIntegration}
              {...(useEmailIntegrationDataToSet
                ? {
                    dataToSet: integrationDataToSet.connectionKeys,
                  }
                : null)}
            />
          ),
        },
        smsService: {
          title: "SMS Gateway",
          component: () => (
            <SetSmsProvider
              onConnected={handleServiceConnected}
              isConnected={connectedServices.includes("smsService")}
              connectionFields={integrations.sms}
              onDisconnect={handleDisconnectIntegration}
            />
          ),
        },
        aiModel: {
          title: "AI Model Provider",
          component: () => (
            <SetAiModel
              onConnected={handleServiceConnected}
              isConnected={connectedServices.includes("aiModel")}
              connectionFields={integrations.aiModel}
              onConnect={(data) =>
                setIntegrations({ ...integrations, aiModel: data })
              }
              onDisconnect={handleDisconnectIntegration}
            />
          ),
        },
        marketingEmailService: {
          title: "Marketing Email Service",
          disabledContent: true,
        },
      },
    },
    emailOptions: {
      title: "Email Options",
      tabs: {
        emailSignature: {
          title: "Signature",
          component: () => <EmailSignature />,
        },
        greetingAndSignature: {
          title: "Manual Greeting & Signature",
          component: () => <GreetingAndSignature />,
        },
        unsubscribe: {
          title: "Unsubscribe",
          component: () => <Unsubscribe />,
        },
        deliverability: {
          title: "Deliverability",
          component: () => <Deliverability />,
        },
        scheduling: {
          title: "Scheduling",
          component: () => <Scheduling />,
        },
      },
    },
    usage: {
      title: "Usage",
      component: () => <Usage />,
    },
  };

  if (isAdmin || isCompanyAdmin) {
    SIDEBAR_TABS.myTeam = {
      title: "My Team",
      component: () => <MyTeam />,
    };

    if (companyInfo.company.subscriptionPlanId) {
      SIDEBAR_TABS.subscription = {
        title: "Subscription & Billing",
        component: () => <Subscription />,
      };
    }
  } else {
    delete SIDEBAR_TABS.emailOptions.tabs.greetingAndSignature;
  }

  if (isAdmin) {
    SIDEBAR_TABS.admin = {
      title: "Admin",
      tabs: {
        simpleConnect: {
          title: "Simple Connect",
          component: () => (
            <SimpleConnect onReloadIntegrations={fetchIntegrations} />
          ),
        },
        prompts: {
          title: "Prompts",
          component: () => <Prompts />,
        },
        samples: {
          title: "Samples",
          component: () => <Samples />,
        },
        users: {
          title: "Users",
          component: () => (
            <Users openCampaignsWithFilter={openCampaignsWithFilter} />
          ),
        },
        companies: {
          title: "Companies",
          component: () => (
            <Companies openCampaignsWithFilter={openCampaignsWithFilter} />
          ),
        },
        campaigns: {
          title: "Campaigns",
          component: () => (
            <Campaigns companyId={filter.companyId} userId={filter.userId} />
          ),
        },
      },
    };
  }

  const [integrations, setIntegrations] = useState({});
  const [connectedServices, setConnectedServices] = useState([]);
  const handleServiceConnected = (service) => {
    setConnectedServices([...connectedServices, service]);
  };

  const isCrmIntegrationTab =
    isCrmIntegration || isHubspotIntegration || useCrmIntegrationDataToSet;
  const isEmailIntegrationTab =
    isEmailIntegrationSection ||
    isOffice365Integration ||
    isGSuiteIntegration ||
    useEmailIntegrationDataToSet;
  const isIntegrationsTab =
    isCrmIntegrationTab || isEmailIntegrationTab || isSmsIntegrationSection;

  const [activeTab, setActiveTab] = useState(
    isIntegrationsTab
      ? Object.keys(SIDEBAR_TABS.integrations.tabs)[
          isSmsIntegrationSection ? 2 : isEmailIntegrationTab ? 1 : 0
        ]
      : isSubscriptionTab
      ? "subscription"
      : "account"
  );
  const [styledTab, setStyledTab] = useState(
    isIntegrationsTab
      ? "integrations"
      : isSubscriptionTab
      ? "subscription"
      : "account"
  );

  const handleTabClick = (key) => {
    if (Object.keys(SIDEBAR_TABS).includes(key)) {
      setStyledTab(key);

      if (MULTI_LEVEL_TABS_LIST.includes(key)) {
        if (key === "integrations") {
          fetchIntegrations();
        }
        setActiveTab(Object.keys(SIDEBAR_TABS[key].tabs)[0]);
      } else {
        setActiveTab(key);
      }

      return;
    }

    const topKey = MULTI_LEVEL_TABS_LIST.find((tk) =>
      Object.keys(SIDEBAR_TABS[tk]?.tabs || {}).includes(key)
    );
    if (topKey === "integrations") {
      fetchIntegrations();
    }
    setStyledTab(topKey);
    setActiveTab(key);
  };

  const openCampaignsWithFilter = (filter) => {
    setFilter(filter);
    setStyledTab("admin");
    setActiveTab("campaigns");
  };

  useEffect(() => {
    const isHubspotAccountsPageWhenAutoConfigure =
      isFailedAutoConfigure &&
      errorMessageForAutoConfigureLog?.includes("isHubspotAccountsPage=true");

    if (isAccessError || isFailedAutoConfigure) {
      const handleError = async () => {
        await fetchAutoConfigureLog({
          logInfo: `[${
            isOffice365Integration
              ? "Office365"
              : isHubspotIntegration
              ? "HubSpot"
              : "GSuite"
          }] ${
            isAccessError
              ? "Access denied"
              : errorMessageForAutoConfigureLog ||
                "Failed to auto-configure integration"
          }`,
        });

        if (isHubspotAccountsPageWhenAutoConfigure) {
          sendMessageToExtension({
            message: "close-iframe",
          });
        }
      };

      if (isFailedAutoConfigure && !isHubspotAccountsPageWhenAutoConfigure) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message:
              "Something went wrong during integration auto-configuration",
          },
        });
      }

      handleError();
    }

    if (isHubspotAccountsPageWhenAutoConfigure) {
      return;
    }

    loadUserData(userInfo, (res) => {
      setIsLoadingUserData(false);

      if (res?.result?.postSignUpOnboarding?.completed !== false) {
        fetchIntegrations();
      }
    });
  }, []);

  const fetchIntegrations = async () => {
    const integrations = await getIntegrations();

    if (!integrations.integration) {
      if (
        integrations.message &&
        activeTab === "emailService" &&
        styledTab === "integrations"
      ) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: integrations.message,
          },
        });
      }
      setIsLoading(false);
      return;
    }

    if (
      integrations.integration.crm &&
      Object.keys(integrations.integration.crm).length > 0
    ) {
      setConnectedServices((oldConnectedServices) => [
        ...oldConnectedServices,
        "crm",
      ]);
    }

    if (integrations.integration.email?.type) {
      setConnectedServices((oldConnectedServices) => [
        ...oldConnectedServices,
        "emailService",
      ]);
    }

    if (integrations.integration.sms?.type) {
      setConnectedServices((oldConnectedServices) => [
        ...oldConnectedServices,
        "smsService",
      ]);
    }

    if (integrations.integration.aiModel?.type) {
      setConnectedServices((oldConnectedServices) => [
        ...oldConnectedServices,
        "aiModel",
      ]);
    }

    setIntegrations(integrations.integration);
    setIsLoading(false);

    return integrations.integration;
  };

  const handleDisconnectIntegration = async (type) => {
    const res = await disconnectIntegration({ type });
    if (res.success) {
      const isOffice365 = type === "office365";
      if (
        integrations.email.method === "oauth" &&
        (isOffice365 || type === "gsuite")
      ) {
        sendMessageToExtension({
          message: "show-success-message",
          data: {
            message: `Please revoke the "SalesStream.ai Reach" app's access to information in your ${
              isOffice365 ? "Office 365 " : "Google"
            } Account settings.`,
            title: "",
          },
        });
        setTimeout(() => {
          window.open(
            isOffice365
              ? "https://account.live.com/consent/Manage"
              : "https://myaccount.google.com/connections",
            "_blank"
          );
        }, 2900);
      }

      await fetchIntegrations();
    } else {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: res.message,
        },
      });
    }
    return res.success;
  };

  const openConfigurationWizard = async (event) => {
    const integration = await fetchIntegrations();
    const isCreatedHubspotIntegrationViaPublicApp =
      integration?.crm?.hubspot?.method === "oauth" && isCompanyAdmin;

    event.preventDefault();
    sendMessageToExtension({
      message: "close-iframe",
    });

    sendMessageToExtension({
      message: "open-configuration-wizard",
      data: {
        isCreatedHubspotIntegrationViaPublicApp,
      },
    });
  };

  const currentEnv = () => {
    const envName = Object.keys(ENVs).find(
      (key) => ENVs[key] === window.location.origin
    );
    return envName;
  };

  if (isLoadingUserData) {
    return (
      <AccountProvider>
        <CheckExtensionVersion />
      </AccountProvider>
    );
  }

  const getSuggestionPopupDesc = () => {
    if (isHubspotIntegration) {
      return "It looks like your HubSpot account does not have the required privileges to create a new Private App.  Please reach out to your HubSpot administrator for assistance.";
    }

    if (isOffice365Integration) {
      return "It looks like your Azure account does not have the required privileges to register an Application.  Please reach out to your Azure administrator for assistance.";
    }

    return "It looks like your Google Cloud account does not have the required privileges to create a new Project.  Please reach out to your Google administrator for assistance.";
  };

  return (
    <>
      {isSuggestionPopupOpen && (
        <SuggestionPopup
          title="Permission Denied"
          description={getSuggestionPopupDesc()}
          onConfirm={() => setIsSuggestionPopupOpen(false)}
          confirmBtnText="Got it!"
          removeCancel={true}
          imageSrc="/images/permission-denied-icon.svg"
        />
      )}
      <Layout>
        <SideBarTitle>Settings</SideBarTitle>
        <ContentContainer>
          <SideBar>
            <TabList>
              {Object.keys(SIDEBAR_TABS).map((key) => {
                return (
                  <div key={key}>
                    <TabItem
                      $flexDirection="column"
                      $alignItems="flex-start"
                      data-active={styledTab === key}
                    >
                      <SidebarItemTitle
                        onClick={() => handleTabClick(key)}
                        data-active={styledTab === key}
                        $styledTab={styledTab}
                      >
                        {SIDEBAR_TABS[key].title}
                      </SidebarItemTitle>
                      {MULTI_LEVEL_TABS_LIST.includes(key) &&
                        styledTab === key && (
                          <TabListIntegration>
                            {Object.keys(SIDEBAR_TABS[key].tabs).map(
                              (subKey) => (
                                <div key={subKey}>
                                  <TabItemIntegration
                                    disabled={
                                      SIDEBAR_TABS[key].tabs[subKey]
                                        .disabledContent
                                    }
                                    data-active={activeTab === subKey}
                                  >
                                    <div
                                      data-tooltip-id={
                                        SIDEBAR_TABS[key].tabs[subKey]
                                          .disabledContent
                                          ? `${subKey}-tooltip`
                                          : null
                                      }
                                      data-tooltip-content={
                                        SIDEBAR_TABS[key].tabs[subKey]
                                          .disabledContent
                                          ? "Coming soon"
                                          : null
                                      }
                                      onClick={() =>
                                        !SIDEBAR_TABS[key].tabs[subKey]
                                          .disabledContent
                                          ? handleTabClick(subKey)
                                          : null
                                      }
                                    >
                                      {SIDEBAR_TABS[key].tabs[subKey].title}
                                    </div>
                                  </TabItemIntegration>
                                  {SIDEBAR_TABS[key].tabs[subKey]
                                    .disabledContent ? (
                                    <Tooltip
                                      id={`${subKey}-tooltip`}
                                      place="right"
                                      className="custom-tooltip"
                                    />
                                  ) : null}
                                </div>
                              )
                            )}
                          </TabListIntegration>
                        )}
                    </TabItem>
                  </div>
                );
              })}
            </TabList>
            <ConfigurationBtn
              onClick={(event) => openConfigurationWizard(event)}
            >
              Configuration Wizard
            </ConfigurationBtn>
            {isAdmin && <EnvName>env: {currentEnv()}</EnvName>}
          </SideBar>
          <Content $activeTab={activeTab}>
            {isLoading ? (
              <Loader parentSize={true} />
            ) : !MULTI_LEVEL_TABS_LIST.includes(activeTab) &&
              SIDEBAR_TABS[activeTab] ? (
              SIDEBAR_TABS[activeTab].component()
            ) : (
              MULTI_LEVEL_TABS_LIST.map((key) => (
                <React.Fragment key={key}>
                  {SIDEBAR_TABS[key]?.tabs[activeTab]?.component()}
                </React.Fragment>
              ))
            )}
          </Content>
        </ContentContainer>
      </Layout>
    </>
  );
};

export default Settings;

const Layout = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  height: calc(100vh - 70px);
  background-color: ${({ theme }) => theme.colors.background_color};
  padding: 1.7% 0 1.8% 4.8%;
`;

const SideBarTitle = styled.div`
  font-size: 24px;
  font-weight: 800;
  font-family: "AlbertSansExtraBold", sans-serif;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 7px;
`;

const ContentContainer = styled.div`
  display: flex;
  height: 97%;
`;

const SideBar = styled.div`
  width: 176px;
  margin-top: 13px;
`;

const SidebarItemTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 10px;
  line-height: 13px;
  cursor: pointer;
  color: ${({ theme, $styledTab }) =>
    !MULTI_LEVEL_TABS_LIST.includes($styledTab)
      ? theme.colors.saturated_purple
      : theme.colors.gray};
  &::before {
    ${(props) =>
      props["data-active"]
        ? `
          content: "";
          position: absolute;
          width: 2px;
          height: 10px;
          border-radius:10px;
          left: 3px;
          `
        : "content: none"};
    background-color: ${({ theme, $styledTab }) =>
      !MULTI_LEVEL_TABS_LIST.includes($styledTab)
        ? theme.colors.saturated_purple
        : theme.colors.gray};
  }
`;

const TabList = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  flex-direction: column;
  font-size: 11px;
  font-weight: 700;
`;

const TabItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: ${({ $flexDirection }) =>
    $flexDirection ? $flexDirection : "row"};
  justify-content: flex-start;
  align-items: ${({ $alignItems }) => ($alignItems ? $alignItems : "center")};
  color: ${(props) => (props.disabled ? "#ccc" : props.theme.colors.gray)};
  font-weight: ${(props) => (props["data-active"] ? "700" : "400")};
`;

const TabListIntegration = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  flex-direction: column;
  font-size: 11px;
  /* margin-top: 8px; */
`;

const TabItemIntegration = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) =>
    props.disabled ? "#ccc" : props.theme.colors.saturated_purple};
  font-weight: ${(props) => (props["data-active"] ? "700" : "400")};

  div {
    cursor: pointer;
    padding: 10px 10px 10px 24px;
  }
`;

const Content = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.divider_color};
  width: 87.5%;
  background-color: white;
  margin: 30px 0 0 23px;
  padding: ${({ $activeTab }) =>
    $activeTab === "account" ||
    $activeTab === "emailSignature" ||
    $activeTab === "usage" ||
    $activeTab === "myTeam" ||
    $activeTab === "unsubscribe"
      ? "0"
      : "43px 0 43px 63px"};
  overflow: auto;
`;

const ConfigurationBtn = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.saturated_purple};
  height: 40px;
  width: 165px;
  font-family: "AlbertSansExtraBold", sans-serif;
  font-weight: 800;
  font-size: 12px;
  border-radius: 6px;
  border: none;
  margin-top: 20px;
`;

const EnvName = styled.div`
  position: absolute;
  bottom: 5px;
  left: 5px;
  font-size: 12px;
  color: grey;
`;
