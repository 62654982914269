import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import StepButton from "../../StepButton";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { validateCampaignGoal, getFilters } from "../../../services/campaigns";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import { CAMPAIGN_TYPES, GOALS, VALIDATE_GOAL_GPT_ANSWERS } from "../constants";
import ValidateGoalPopup from "./ValidateGoalPopup";
import { isBullhornMarketingCampaignType } from "../utils";
import { FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS } from "../../../utils/constants";
import Loader from "../../Loader";
import FirstTimeCampaignOnboarding from "../../FirstTimeCampaignOnboarding";

function CampaignDetails({
  campaign,
  setCampaign,
  handleDecreaseStep,
  handleIncreaseStep,
}) {
  const previewTextType = CAMPAIGN_TYPES[campaign.crmType]?.includes(
    campaign.type
  )
    ? campaign.type
    : "other";
  const parentRef = useRef(null);
  const [details, setDetails] = useState(campaign.details);
  const [validateGoalGPTAnswer, setValidateGoalGPTAnswer] = useState("");
  const [isValidateGoalPopupOpened, setIsValidateGoalPopupOpened] =
    useState(false);
  const [isValidationLoading, setIsValidationLoading] = useState(false);
  const [isPreviewTextLoading, setIsPreviewTextLoading] = useState(
    !campaign.detailsPreview?.options
  );
  const [previewText, setPreviewText] = useState(
    campaign.detailsPreview?.options?.[previewTextType]
  );

  const firstTimeCampaignOnboardingConfig = {
    ...FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.campaignDetails,
    parentRef,
  };

  useEffect(() => {
    if (isPreviewTextLoading) {
      const getConfig = async () => {
        const filtersData = await getFilters({
          filter: ["campaignDetails"],
        });
        const options = filtersData.result?.campaignDetails;
        setPreviewText(options?.[previewTextType]);
        setCampaign((campaign) => ({
          ...campaign,
          detailsPreview: { options },
        }));
        setIsPreviewTextLoading(false);
      };
      getConfig();
    }
  }, [isPreviewTextLoading]);

  const isChanged = () => {
    return campaign.details !== details;
  };

  const handleClickBack = (event) => {
    event.preventDefault();
    setCampaign((campaign) => ({
      ...campaign,
      details,
      jobDescriptionChanged: isChanged(),
    }));
    handleDecreaseStep();
  };

  const changeGoalDescription = () => {
    if (validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.emailReply) {
      return 'It looks like you\'d like your recipients to simply reply to your message.  Changing your campaign goal to "Any email reply" will allow Reach to better track goal completions.';
    }

    if (validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.link) {
      return 'It looks like you\'d like your recipients to click on a link.  Changing your campaign goal to "Get contact to visit a link" will allow Reach to automatically track successful clicks.';
    }

    return "";
  };

  const confirmChangeGoal = (link) => {
    if (validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.link) {
      setCampaign((campaign) => ({
        ...campaign,
        goal: GOALS.visitLink,
        link,
        isCustomGoal: false,
      }));

      handleIncreaseStep();
      return;
    }

    if (validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.emailReply) {
      setCampaign((campaign) => ({
        ...campaign,
        goal: GOALS.emailReply,
        isCustomGoal: false,
      }));

      handleIncreaseStep();
      return;
    }
  };

  const cancelChangeGoal = () => {
    handleIncreaseStep();
  };

  const handleClickNext = async (event) => {
    event.preventDefault();

    if (details === "") {
      return;
    }

    setCampaign((campaign) => ({
      ...campaign,
      details,
      jobDescriptionChanged: isChanged(),
    }));

    if (!campaign.isCustomGoal) {
      handleIncreaseStep();
      return;
    }

    setIsValidationLoading(true);

    const data = {
      crm: campaign.crmType,
      goal: campaign.goal,
      campaign_type: campaign.type,
      campaign_details: details,
    };
    const response = await validateCampaignGoal(data);

    if (!response.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: response.message,
        },
      });
      handleIncreaseStep();
      return;
    }

    if (
      Object.values(VALIDATE_GOAL_GPT_ANSWERS).includes(response.result.result)
    ) {
      if (response.result.result === VALIDATE_GOAL_GPT_ANSWERS.custom) {
        handleIncreaseStep();
        return;
      }
      setValidateGoalGPTAnswer(response.result.result);
      setIsValidateGoalPopupOpened(true);
      setIsValidationLoading(false);
    } else {
      handleIncreaseStep();
    }
  };

  const handleChange = (value, delta, source, editor) => {
    const text = editor.getText();
    if (!text?.replace(/\r?\n/g, "").trim()) {
      setDetails("");
      return;
    }

    setDetails(value);
  };

  return isPreviewTextLoading ? (
    <Loader
      parentSize={true}
      bgColor="#F5F2EE"
      padding="0 30px 30px 0"
      text="Loading preview..."
    />
  ) : (
    <>
      <Section ref={parentRef}>
        <FirstTimeCampaignOnboarding
          config={firstTimeCampaignOnboardingConfig}
        />
        <Title>Campaign Details</Title>
        <InputBlock>
          <Label fontWeight="bold" htmlFor="campaign-details">
            {isBullhornMarketingCampaignType(campaign.type)
              ? "Enter a detailed description of the marketing campaign.  This will be the basis of the customized communications received by each candidate"
              : "Enter a detailed description of your campaign"}
          </Label>
          <InputTextarea
            id="details"
            placeholder={previewText || "Campaign Details"}
            value={details}
            onChange={handleChange}
          />
        </InputBlock>
      </Section>
      <ButtonGroup>
        <StepButton onClick={handleClickBack} isNext={false} />
        <StepButton
          onClick={handleClickNext}
          disabled={details === "" || isValidationLoading}
          isNext={true}
        />
      </ButtonGroup>
      {isValidateGoalPopupOpened && (
        <ValidateGoalPopup
          title="There’s something else you can do here!"
          description={changeGoalDescription()}
          showLinkInput={
            validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.link
          }
          onConfirm={confirmChangeGoal}
          onCancel={cancelChangeGoal}
        />
      )}
    </>
  );
}

export default CampaignDetails;

const Section = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 100px);
`;

const Label = styled.label`
  margin: 8px 0;
  color: ${({ theme }) => theme.colors.btn_border_color};
  font-weight: 600;
  font-size: 15px;
`;

const ButtonGroup = styled.div`
  grid-row-start: 2;
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  align-items: end;
`;

const InputTextarea = styled(ReactQuill)`
  margin-top: 15px;
  width: 100%;
  height: calc(100vh - 300px);
  * {
    background-color: white;
  }
`;
