import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

import Navbar from "./Navbar";
import { AccountProvider } from "../context";
import CheckExtensionVersion from "./CheckExtensionVersion";

function MainContent() {
  const [campaignBeingPrepared, setCampaignBeingPrepared] = useState(null);

  return (
    <AccountProvider>
      <CheckExtensionVersion />
      <GlobalLayout>
        <Navbar campaignBeingPrepared={campaignBeingPrepared} />
        <Wrapper>
          <Outlet context={[campaignBeingPrepared, setCampaignBeingPrepared]} />
        </Wrapper>
      </GlobalLayout>
    </AccountProvider>
  );
}

export default MainContent;

const GlobalLayout = styled.main`
  height: 100%;
`;

const Wrapper = styled.div`
  background-color: #f5f2ee;
  height: calc(100vh - 70px);
`;
