/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ContentSteps from "./ContentSteps/ContentSteps";
import ResumeCampaignStep from "./ContentSteps/ResumeCampaignStep";
import SidePanel from "./SidePanel";
import { styled } from "styled-components";
import { getWizardTabs } from "./utils";
import { CRMS } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import CloseBtn from "../CloseBtn";
import { sendMessageToExtension } from "../../utils/postToExtension";
import {
  getObjectFromLocalStorage,
  removeCreateCampaignDataFromLocalStorage,
} from "../../api/localStorage";
import { FIRST_WIZARD_TAB_NUM } from "./constants";
import Loader from "../Loader";
import getUserFromLocalStorage from "../../utils/getUserFromLocalStorage";
import {
  isUserInFirstTimeCampaignOnboardingUserList,
  updateFirstTimeCampaignOnboardingUserList,
} from "../../utils/utils";

function CampaignWizard({
  crm,
  isResumeStep = false,
  date,
  handleResume,
  handleStartOver,
}) {
  const location = useLocation();
  const {
    isMarketingCampaign,
    isEmailIntegration,
    isSmsIntegration,
    isRecreatingCampaignIframe,
  } = queryString.parse(location.search);

  const isBullhornCrm = crm === CRMS.BULLHORN;

  const [bullhornCampaignData, setBullhornCampaignData] = useState(
    isBullhornCrm && isMarketingCampaign === "true"
      ? { isMarketingCampaign: true }
      : null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [campaignType, setCampaignType] = useState(null);

  const [activeTab, setActiveTab] = useState(FIRST_WIZARD_TAB_NUM);

  const currentTempCampaign = getObjectFromLocalStorage("currentTempCampaign");
  const isExistsTempCampaign =
    currentTempCampaign?.activeTab && currentTempCampaign.name;
  const userInfo = getUserFromLocalStorage();
  const postMessageEventHandler = (event) => {
    if (!event.data.jobDescription && !event.data.publishedDescription) {
      return;
    }

    window.removeEventListener("message", postMessageEventHandler);

    setBullhornCampaignData({
      jobDescription: {
        jobDescription: event.data.jobDescription,
        publishedDescription: event.data.publishedDescription,
      },
      payRate: event.data.payRate,
      jobTitle: event.data.jobTitle,
    });
  };

  const onClose = () => {
    removeCreateCampaignDataFromLocalStorage();
    sendMessageToExtension({
      message: "cancel-campaign-creation",
    });
  };

  useEffect(() => {
    if (isResumeStep) {
      return;
    }

    if (isExistsTempCampaign) {
      setBullhornCampaignData(null);

      setCampaignType(currentTempCampaign.type);

      const lastTab = getWizardTabs(
        crm,
        currentTempCampaign.type
      ).REVIEW_MESSAGES;

      let resumeTab = currentTempCampaign.activeTab;
      if (resumeTab === lastTab) {
        resumeTab--;
      }
      setActiveTab(resumeTab);
    } else if (isBullhornCrm && !bullhornCampaignData?.isMarketingCampaign) {
      window.addEventListener("message", postMessageEventHandler);

      sendMessageToExtension({
        message: "get-job-description",
      });
    }
  }, [isResumeStep]);

  useEffect(() => {
    const isUserExist = isUserInFirstTimeCampaignOnboardingUserList({
      userId: userInfo?.id,
    });

    if (isUserExist) {
      return;
    }

    if (userInfo?.hasCreatedCampaign) {
      updateFirstTimeCampaignOnboardingUserList({
        userId: userInfo?.id,
        initializeWithDefaultNames: true,
      });
    }
  }, []);

  return isLoading ? (
    <Loader
      parentSize={true}
      {...(typeof isLoading === "string"
        ? { text: isLoading, textColor: "#e67fed" }
        : null)}
    />
  ) : (
    <main className="app">
      <CloseBtn onClose={onClose} width={20} />
      <div className="layout">
        <SidePanel
          activeTab={activeTab}
          crm={crm}
          type={campaignType}
          isResumeStep={isResumeStep}
        />
        <Section>
          {isResumeStep ? (
            <ResumeCampaignStep
              date={date}
              handleResume={handleResume}
              handleStartOver={handleStartOver}
            ></ResumeCampaignStep>
          ) : isBullhornCrm &&
            !bullhornCampaignData &&
            !isExistsTempCampaign ? (
            <div />
          ) : (
            <ContentSteps
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              setCampaignType={setCampaignType}
              isRecreatingCampaignIframe={isRecreatingCampaignIframe}
              crm={crm}
              bullhornCampaignData={bullhornCampaignData}
              isEmailIntegration={isEmailIntegration === "true"}
              isSmsIntegration={isSmsIntegration === "true"}
              setIsLoading={setIsLoading}
            />
          )}
        </Section>
      </div>
    </main>
  );
}

export default CampaignWizard;

const Section = styled.div`
  width: 75%;
  background-color: #f5f2ee;
  display: flex;
  align-items: center;
`;
