import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import CustomRoutes from "./CustomRoutes";
import {
  Campaigns,
  Settings,
  Loading,
  Alert,
  Support,
  Reports,
  Login,
  ContactUs,
  Register,
  Subscription,
  CreateCampaign,
  CreateIntegrations,
  AutomateO365KeyGen,
  AutomateHubspotAPI,
  PostSignUpOnboarding,
  MinimizedCampaignPrep,
  InstallHubspotApp,
} from "./pages";
import {
  getDataFromLocalStorage,
  saveDataToLocalStorage,
} from "./api/localStorage";

import { ThemeProvider } from "styled-components";
import theme from "./theme";
import { CRMS, LOGIN_PAGE_CONTENT_LIST } from "./utils/constants";
import { sendMessageToExtension } from "./utils/postToExtension";
import { clearCache } from "./utils/clearCache";
import packageJSON from "../package.json";
import { gtmId, gsuiteOauthClientId, isNeedToClearCache } from "./utils/config";
import AutomateGsuiteKeyGen from "./pages/AutomateGsuiteKeyGen";
import { MsalProvider } from "@azure/msal-react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { msalConfig } from "./utils/auth-o365-config";
import { PublicClientApplication } from "@azure/msal-browser";
import TagManager from "react-gtm-module";
import MainContentLayout from "./components/MainContentLayout";

const msalInstance = new PublicClientApplication(msalConfig);

!!gtmId && TagManager.initialize({ gtmId });

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  const postMessageEventHandler = (event) => {
    if (!event?.data?.iframeStorageData) {
      if (window.location.pathname === "/install-hubspot-app") {
        window.removeEventListener("message", postMessageEventHandler);
        setIsLoading(false);
      }
      return;
    }

    window.removeEventListener("message", postMessageEventHandler);
    for (const key in event.data.iframeStorageData) {
      saveDataToLocalStorage(key, event.data.iframeStorageData[key], true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const checkVersion = () => {
      if (!isNeedToClearCache) {
        return;
      }
      const savedVersion = getDataFromLocalStorage("version");
      const currentVersion = packageJSON.version;

      if (savedVersion !== currentVersion) {
        clearCache();
        saveDataToLocalStorage("version", currentVersion);
        return;
      }
    };

    checkVersion();

    sendMessageToExtension({
      message: "get-storage-data",
    });
    window.addEventListener("message", postMessageEventHandler);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {!isLoading && (
        <MsalProvider instance={msalInstance}>
          <GoogleOAuthProvider clientId={gsuiteOauthClientId}>
            <Router>
              <CustomRoutes>
                <Route
                  path="install-hubspot-app"
                  element={<InstallHubspotApp />}
                />
                <Route path="/" element={<MainContentLayout />}>
                  <Route path="campaigns" element={<Campaigns />} />
                  <Route path="settings" element={<Settings />} />
                  <Route path="reports" element={<Reports />} />
                  <Route path="support" element={<Support />} />
                </Route>
                <Route path="loading" element={<Loading />} />
                <Route path="alert" element={<Alert />} />
                <Route
                  path="login"
                  element={
                    <Login loginPageContent={LOGIN_PAGE_CONTENT_LIST.LOGIN} />
                  }
                />
                <Route
                  path="ask-reset-password"
                  element={
                    <Login
                      loginPageContent={
                        LOGIN_PAGE_CONTENT_LIST.ASK_RESET_PASSWORD
                      }
                    />
                  }
                />
                <Route
                  path="reset-password"
                  element={
                    <Login
                      loginPageContent={LOGIN_PAGE_CONTENT_LIST.RESET_PASSWORD}
                    />
                  }
                />
                <Route path="contact-us" element={<ContactUs />} />
                <Route path="register" element={<Register />} />
                <Route path="subscription-plans" element={<Subscription />} />
                <Route
                  path="create-campaign-bullhorn"
                  element={<CreateCampaign crm={CRMS.BULLHORN} />}
                />
                <Route
                  path="create-campaign-hubspot"
                  element={<CreateCampaign crm={CRMS.HUBSPOT} />}
                />
                <Route
                  path="create-campaign-google-sheets"
                  element={<CreateCampaign crm={CRMS.GOOGLE_SHEETS} />}
                />
                <Route
                  path="create-integrations"
                  element={<CreateIntegrations />}
                />
                <Route
                  path="automate-o365-key-gen"
                  element={<AutomateO365KeyGen />}
                />
                <Route
                  path="automate-hubspot-api"
                  element={<AutomateHubspotAPI />}
                />
                <Route
                  path="automate-gsuite-key-gen"
                  element={<AutomateGsuiteKeyGen />}
                />
                <Route
                  path="post-sign-up-onboarding"
                  element={<PostSignUpOnboarding />}
                />
                <Route
                  path="min-campaigns-prep"
                  element={<MinimizedCampaignPrep />}
                />
              </CustomRoutes>
            </Router>
          </GoogleOAuthProvider>
        </MsalProvider>
      )}
    </ThemeProvider>
  );
};

export default App;
