import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Loader from "../../Loader";
import { getStrategies } from "../../../services/campaigns";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import {
  CRMS,
  FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS,
} from "../../../utils/constants";
import StepButton from "../../StepButton";
import { isBullhornMarketingCampaignType } from "../utils";
import FirstTimeCampaignOnboarding from "../../FirstTimeCampaignOnboarding";

function SalesStrategy({
  campaign,
  setCampaign,
  handleDecreaseStep,
  handleIncreaseStep,
  crm,
}) {
  const parentRef = useRef(null);
  const [strategies, setStrategies] = useState([]);
  const [isCustomStrategyInputOpened, setIsCustomStrategyInputOpened] =
    useState(false);
  const [selectedStrategy, setStrategy] = useState(campaign.strategy);
  const [customStrategy, setCustomStrategy] = useState("");
  const [customStrategyErrorMessage, setCustomStrategyErrorMessage] =
    useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [fetchStrategiesErrorMessage, setFetchStrategiesErrorMessage] =
    useState("");
  const [isLoading, setIsLoading] = useState(true);

  const firstTimeCampaignOnboardingConfig = {
    ...FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.salesStrategy,
    parentRef,
  };

  const handleChange = (strategy) => {
    setErrorMessage("");
    setCustomStrategyErrorMessage("");

    setStrategy(strategy);
    setIsCustomStrategyInputOpened(false);
    setCustomStrategy("");
  };

  const handleDefineNewStrategy = () => {
    setIsCustomStrategyInputOpened(true);

    setErrorMessage("");
    setCustomStrategyErrorMessage("");

    setStrategy("");
  };

  const handleClickNext = (event) => {
    event.preventDefault();
    if (selectedStrategy === "" && !isCustomStrategyInputOpened) {
      setErrorMessage("Please choose a campaign strategy");
      return;
    }
    if (selectedStrategy === "" && isCustomStrategyInputOpened) {
      if (customStrategy.length < 1) {
        setCustomStrategyErrorMessage("This field is required");
        return;
      }
      if (customStrategy.length > 0) {
        setCustomStrategyErrorMessage("");
        setCampaign((campaign) => ({ ...campaign, strategy: customStrategy }));
        handleIncreaseStep();
        return;
      }
    }

    setCampaign((campaign) => ({
      ...campaign,
      strategy: selectedStrategy,
    }));
    handleIncreaseStep();
  };

  const handleClickBack = (event) => {
    event.preventDefault();
    if (
      selectedStrategy === "" &&
      isCustomStrategyInputOpened &&
      customStrategy !== ""
    ) {
      setCampaign((campaign) => ({
        ...campaign,
        strategy: customStrategy,
      }));
      handleDecreaseStep();
      return;
    }
    if (selectedStrategy !== "") {
      setCampaign((campaign) => ({
        ...campaign,
        strategy: selectedStrategy,
      }));
      handleDecreaseStep();
      return;
    }
    setCampaign((campaign) => ({
      ...campaign,
      strategy: "",
    }));
    handleDecreaseStep();
  };

  const fetchStrategies = async () => {
    setIsLoading(true);

    const data = {
      crm,
    };

    if (
      crm === CRMS.BULLHORN &&
      !isBullhornMarketingCampaignType(campaign.type)
    ) {
      const job_description =
        campaign.jobDescription[campaign.jobDescriptionKey];
      const job_pay_rate = campaign.payRate;

      data.job_description = job_description;
      data.job_pay_rate = job_pay_rate;
    } else {
      data.campaign_type = campaign.type;
      data.campaign_details = campaign.details;
    }

    const response = await getStrategies(data);

    if (response.success !== undefined && !response.success) {
      setFetchStrategiesErrorMessage(response.message);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: response.isMoneyInsufficient
            ? "You do not have enough credits to perform this operation"
            : "Unfortunately, we had trouble fetching strategies",
        },
      });
      setIsLoading(false);
      handleDecreaseStep();
      return;
    }

    const currentStrategies = Object.values(response.result.result);

    setFetchStrategiesErrorMessage(null);
    setStrategies(currentStrategies);
    setCampaign((campaign) => ({
      ...campaign,
      localStrategies: currentStrategies,
      strategy: "",
      jobDescriptionChanged: false,
    }));
    setIsLoading(false);
  };

  useEffect(() => {
    if (!campaign.localStrategies || campaign.jobDescriptionChanged) {
      return;
    }
    setStrategies(campaign.localStrategies);
    if (
      !campaign.localStrategies.includes(campaign.strategy) &&
      campaign.strategy !== ""
    ) {
      setIsCustomStrategyInputOpened(true);
      setCustomStrategy(campaign.strategy);
      setIsLoading(false);
      return;
    }

    setIsCustomStrategyInputOpened(false);
    setCustomStrategy("");
    setStrategy(campaign.strategy);
    setIsLoading(false);
  }, [campaign]);

  useEffect(() => {
    if (!campaign.localStrategies || campaign.jobDescriptionChanged) {
      fetchStrategies();
    }
  }, [campaign]);

  return (
    <>
      {isLoading ? (
        <Loader
          parentSize={true}
          bgColor="#F5F2EE"
          padding="0 30px 30px 0"
          text="Generating campaign themes..."
        />
      ) : fetchStrategiesErrorMessage ? (
        <ErrorMessage>{fetchStrategiesErrorMessage}</ErrorMessage>
      ) : (
        <>
          <Section ref={parentRef}>
            <FirstTimeCampaignOnboarding
              config={firstTimeCampaignOnboardingConfig}
            />
            <Title>Message Theme</Title>
            <SelectBlock>
              <Label fontWeight="bold">
                Select a theme for the messages in this campaign
              </Label>
            </SelectBlock>
            <RadioButtonGroup>
              {strategies.map((strategy, index) => (
                <RadioBlock key={index}>
                  <div>
                    <RadioInput
                      id={strategy}
                      value={strategy}
                      name="goal"
                      type="radio"
                      checked={strategy === selectedStrategy}
                      onChange={() => handleChange(strategy)}
                    />
                    <RadioInputCustomChecked
                      $checked={strategy === selectedStrategy}
                    />
                  </div>
                  <RadioInputLabel
                    htmlFor={strategy}
                    $checked={strategy === selectedStrategy}
                    onClick={() => handleChange(strategy)}
                  >
                    {strategy}
                  </RadioInputLabel>
                </RadioBlock>
              ))}
            </RadioButtonGroup>

            <Divider />

            <CustomGoalLabel onClick={handleDefineNewStrategy}>
              + Define a New Theme
            </CustomGoalLabel>
            {isCustomStrategyInputOpened && (
              <InputContent>
                <Relative>
                  <CustomGoalInput
                    type="text"
                    placeholder="Enter the strategy"
                    value={customStrategy}
                    onInput={(event) => setCustomStrategy(event.target.value)}
                  />
                  {customStrategyErrorMessage !== "" && (
                    <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                  )}
                </Relative>
                {customStrategyErrorMessage !== "" && (
                  <ErrorMessage>{customStrategyErrorMessage}</ErrorMessage>
                )}
              </InputContent>
            )}
            {errorMessage !== "" && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </Section>
          <ButtonGroup>
            <StepButton onClick={handleClickBack} isNext={false} />
            <StepButton onClick={handleClickNext} isNext={true} />
          </ButtonGroup>
        </>
      )}
    </>
  );
}

export default SalesStrategy;

const Section = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const SelectBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const Label = styled.label`
  margin: 8px 0;
  color: ${({ theme }) => theme.colors.btn_border_color};
  font-weight: 600;
  font-size: 15px;
`;

const RadioButtonGroup = styled.div``;

const RadioBlock = styled.div`
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  color: #2f2f2f;
  font-size: 13px;
  align-items: center;
  padding: 5px 0;
`;

const RadioInput = styled.input.attrs({ type: "radio" })`
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0 10px;

  display: ${({ checked }) => (checked ? "none" : "block")};
`;

const RadioInputCustomChecked = styled.div`
  display: ${({ $checked }) => ($checked ? "block" : "none")};
  cursor: pointer;
  background-image: url("images/checked-radio-btn-icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 20px;
  height: 20px;
  margin: 0 10px;
`;

const RadioInputLabel = styled.label`
  cursor: pointer;
  padding: 0 12px;
  font-weight: ${({ $checked }) => ($checked ? "700" : "400")};
  color: ${({ theme, $checked }) =>
    $checked ? theme.colors.saturated_purple : "#5A5A5A"};
`;

const CustomGoalLabel = styled.div`
  color: #4640de;
  font-size: 13px;
  margin-left: 50px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const Divider = styled.div`
  width: 15px;
  height: 2px;
  background-color: #858585;
  margin: 10px 50px 20px 50px;
`;

const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
`;

const Relative = styled.div`
  position: relative;
`;

const CustomGoalInput = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin: 0;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin: 0;
  }
`;

const ErrorIcon = styled.img`
  position: absolute;
  right: 12%;
  top: 24%;
`;

const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;

const ButtonGroup = styled.div`
  grid-row-start: 2;
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  align-items: end;
`;
