import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  getPreviewMessage,
  updatePreviewMessage,
} from "../../../services/campaigns";
import Loader from "../../Loader";
import ConfirmLaunchPopup from "./ConfirmLaunchPopup";
import ConfirmPopup from "../../ConfirmPopup";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import getUserFromLocalStorage from "../../../utils/getUserFromLocalStorage";
import {
  getObjectFromLocalStorage,
  saveObjectToLocalStorage,
} from "../../../api/localStorage";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import StepButton from "../../StepButton";
import CustomSelect from "../../CustomSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMessage } from "@fortawesome/free-solid-svg-icons";
import { openPreviewMessageLinkInNewTab } from "../../../utils/utils";

const CONTENT_TYPES = {
  EMAIL: "email",
  PROMPT: "prompt",
};

const buildPromptSequence = (promptSequence) => {
  if (!promptSequence || !promptSequence?.length) {
    return "No Prompt";
  }

  return promptSequence.map((prompt, index) => {
    return (
      <PromptItem key={index}>
        <PromptRole>
          <PromptRoleLabel>Role:</PromptRoleLabel>
          <PromptRoleValue>{prompt.role}</PromptRoleValue>
        </PromptRole>
        <PromptContent>
          <PromptContentLabel>Content:</PromptContentLabel>
          <PromptContentValue>
            {prompt.content.replace(/\\n/g, "\n")}
          </PromptContentValue>
        </PromptContent>
      </PromptItem>
    );
  });
};

function PreviewMessage({
  campaign,
  candidates,
  handleClose,
  isCreatedDraftCampaign,
  handleDecreaseStep,
  isCreatingCampaign,
  finishCreateCampaign,
  generateAll,
}) {
  const textareaRef = useRef(null);

  const [activeCandidateIndex, setActiveCandidateIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentSequence, setCurrentSequence] = useState(null);
  const [currentMessageAttempt, setCurrentMessageAttempt] = useState(null);
  const [currentMessageType, setCurrentMessageType] = useState(null);
  const [currentMessageSubject, setCurrentMessageSubject] = useState(null);
  const [currentMessageText, setCurrentMessageText] = useState(null);
  const [currentPrompt, setCurrentPrompt] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [isConfirmLaunchPopupOpened, setIsConfirmLaunchPopupOpened] =
    useState(false);
  const [isCreditLimitsPopupOpened, setIsCreditLimitsPopupOpened] =
    useState(false);

  const [updatedMessageSubject, setUpdatedMessageSubject] = useState(null);
  const [updatedMessageText, setUpdatedMessageText] = useState(null);

  const [viewedCandidatesIds, setViewedCandidatesIds] = useState([]);
  const [activeContent, setActiveContent] = useState(CONTENT_TYPES.EMAIL);

  const [isLoading, setIsLoading] = useState(true);

  const [isLoadFailed, setIsLoadFailed] = useState(false);

  const [isRegenerating, setIsRegenerating] = useState(false);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCandidates = candidates.filter((item) => {
    return !item.isInvalid;
  });

  const filteredData = filteredCandidates.filter((item) => {
    return (
      ((item.full_name || "")
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
        (item.first_name || "")
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        (item.last_name || "")
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) &&
      !item.isInvalid
    );
  });

  const isAdmin = () => {
    const userInfo = getUserFromLocalStorage();
    if (!userInfo) {
      return false;
    }
    return userInfo.role === "admin";
  };

  const toggleActiveContent = (e) => {
    e.preventDefault();

    if (activeContent === CONTENT_TYPES.EMAIL) {
      setActiveContent(CONTENT_TYPES.PROMPT);
    } else {
      setActiveContent(CONTENT_TYPES.EMAIL);
    }
  };

  const fetchPreviewMessage = async (isRegenerate, messageAttempt) => {
    setIsLoading(true);
    if (isRegenerate) {
      setIsRegenerating(true);
    }

    const currentDraftCampaign = getObjectFromLocalStorage(
      "currentDraftCampaign"
    );

    if (!currentDraftCampaign && !isCreatedDraftCampaign) {
      console.log("create campaign error", "no currentDraftCampaign");

      sendMessageToExtension({
        message: "create-campaign-error",
      });
      return;
    }

    const campaignId = isCreatedDraftCampaign
      ? campaign.id
      : currentDraftCampaign.id;
    const candidateId = isCreatedDraftCampaign
      ? filteredCandidates[activeCandidateIndex].externalId
      : filteredCandidates[activeCandidateIndex].id;

    const data = await getPreviewMessage({
      campaignId,
      candidateId,
      regenerate: isRegenerate,
      attempt: messageAttempt || currentMessageAttempt || 1,
    });

    if (data.success !== undefined && !data.success) {
      if (isCreatedDraftCampaign) {
        setIsLoadFailed(true);
      } else {
        let message = "Unfortunately, we had trouble generating the preview";

        if (data.isMoneyInsufficient) {
          message = "You do not have enough credits to perform this operation";
        }

        if (data.inProgress) {
          message = data.errorMessage;
        }

        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message,
          },
        });
      }
      setIsLoading(false);
      setIsRegenerating(false);
      return;
    }

    markCandidateAsViewed(candidateId, campaignId);

    setCurrentSequence(
      data.result.sequence?.map((s) => ({
        value: s.attempt,
        label: (
          <MessageLabel>
            <FontAwesomeIcon
              icon={s.type === "sms" ? faMessage : faEnvelope}
              color="#787878"
            />
            {`Message #${s.attempt}`}
          </MessageLabel>
        ),
      }))
    );
    setCurrentMessageAttempt(data.result.message.attempt);
    setCurrentMessageType(data.result.message.type);
    setCurrentMessageSubject(data.result.message.subject);
    setCurrentMessageText(data.result.message.text);
    setCurrentPrompt(data.result.prompt || []);

    setUpdatedMessageSubject(data.result.message.subject);
    setUpdatedMessageText(data.result.message.text);

    setActiveContent(CONTENT_TYPES.EMAIL);

    setIsEditing(false);

    setIsLoadFailed(false);

    setIsLoading(false);

    setIsRegenerating(false);
  };

  const handleSelectMessage = (messageAttempt) => {
    fetchPreviewMessage(false, messageAttempt);
  };

  const editMessage = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const currentDraftCampaign = getObjectFromLocalStorage(
      "currentDraftCampaign"
    );

    if (!currentDraftCampaign && !isCreatedDraftCampaign) {
      console.log("create campaign error", "no currentDraftCampaign");

      sendMessageToExtension({
        message: "create-campaign-error",
      });
      return;
    }

    const campaignId = isCreatedDraftCampaign
      ? campaign.id
      : currentDraftCampaign.id;
    const candidateId = isCreatedDraftCampaign
      ? filteredCandidates[activeCandidateIndex].externalId
      : filteredCandidates[activeCandidateIndex].id;

    const data = await updatePreviewMessage({
      campaignId,
      candidateId,
      data: {
        attempt: currentMessageAttempt,
        text: updatedMessageText,
        subject: updatedMessageSubject,
      },
    });

    if (data.success !== undefined && !data.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Unfortunately, we had trouble editing the preview message",
        },
      });
      if (isCreatedDraftCampaign) {
        handleClose();
      }
      setIsLoading(false);
      return;
    }

    setCurrentMessageSubject(updatedMessageSubject);
    setCurrentMessageText(updatedMessageText);

    setIsEditing(false);
    setIsLoading(false);
  };

  const getViewedCandidatesFromStorage = () => {
    return getObjectFromLocalStorage("viewedCandidatesIds") || {};
  };

  const markCandidateAsViewed = (candidateId, campaignId) => {
    const viewedAllIdsFromStorage = getViewedCandidatesFromStorage();
    const viewedIdsFromStorage = viewedAllIdsFromStorage[campaignId] || [];
    const viewedIdsSet = new Set([...viewedIdsFromStorage, candidateId]);

    setViewedCandidatesIds(Array.from(viewedIdsSet));
    saveObjectToLocalStorage("viewedCandidatesIds", {
      ...viewedAllIdsFromStorage,
      [campaignId]: Array.from(viewedIdsSet),
    });
  };

  const setTextareaHeight = () => {
    const textarea = textareaRef.current;

    if (textarea?.style) {
      textarea.style.height = "19px";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (isEditing) {
      setTextareaHeight();
      setUpdatedMessageSubject(currentMessageSubject);
      setUpdatedMessageText(currentMessageText);
    }
  }, [isEditing]);

  useEffect(() => {
    const currentDraftCampaign = getObjectFromLocalStorage(
      "currentDraftCampaign"
    );

    const campaignId = isCreatedDraftCampaign
      ? campaign.id
      : currentDraftCampaign?.id;

    if (!campaignId) {
      return;
    }

    const viewedCandidatesIds = getViewedCandidatesFromStorage();

    setViewedCandidatesIds(viewedCandidatesIds[campaignId] || []);
  }, [campaign, isCreatedDraftCampaign]);

  const handleInput = (e) => {
    e.preventDefault();

    setUpdatedMessageSubject(e.target.value);

    setTextareaHeight();
  };

  const closeCreditLimitsPopup = (e) => {
    e.preventDefault();
    setIsCreditLimitsPopupOpened(false);
  };

  const closePopup = (e) => {
    e.preventDefault();
    setIsConfirmLaunchPopupOpened(false);
  };

  const regenerateMessage = (e) => {
    e.preventDefault();

    fetchPreviewMessage(true);
  };

  const startGenerateAll = (e) => {
    e.preventDefault();
    setIsLoading(true);
    generateAll(e);
  };

  const finishWithCreditsCheck = (e) => {
    e.preventDefault();

    if (campaign.creditsRemaining < campaign.estimatedToken) {
      setIsCreditLimitsPopupOpened(true);
      return;
    }

    setIsConfirmLaunchPopupOpened(true);
  };

  useEffect(() => {
    if (!isCreatingCampaign) {
      fetchPreviewMessage(false, 1);
      return;
    }

    setIsLoading(true);
  }, [activeCandidateIndex, isCreatingCampaign]);

  return (
    <PreviewContainer $isCreatedDraftCampaign={isCreatedDraftCampaign}>
      {isConfirmLaunchPopupOpened && (
        <ConfirmLaunchPopup
          cancelLaunch={closePopup}
          confirmLaunch={finishCreateCampaign}
        />
      )}
      {isCreditLimitsPopupOpened && (
        <ConfirmPopup
          title="Launch Campaign"
          description="This campaign is likely to use more credits than you have 
              remaining and will not be completed. Are you sure you want to proceed?"
          onConfirm={finishCreateCampaign}
          onCancel={closeCreditLimitsPopup}
        />
      )}
      <Content $isCreatedDraftCampaign={isCreatedDraftCampaign}>
        <PreviewContent>
          <LeftSidePanel $isCreatedDraftCampaign={isCreatedDraftCampaign}>
            <TitleBlock $isCreatedDraftCampaign={isCreatedDraftCampaign}>
              {isCreatedDraftCampaign && (
                <CloseIcon
                  src="/images/email-details-back-arrow.png"
                  onClick={handleClose}
                />
              )}
              <PreviewHeaderTitle
                $isCreatedDraftCampaign={isCreatedDraftCampaign}
              >
                All Contacts
              </PreviewHeaderTitle>

              <PreviewHeaderSubTitle>
                <span>
                  {filteredCandidates.length} contact
                  {filteredCandidates.length > 1 ? "s" : ""}
                </span>
              </PreviewHeaderSubTitle>
            </TitleBlock>
            <Search>
              <SearchInput
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Search"
              />
              <SearchIcon src="/images/search-icon.png" />
            </Search>
            <CandidateTabsBlock>
              {filteredCandidates.map((candidate, index) => {
                return (
                  filteredData.includes(candidate) && (
                    <CandidateTabTitle
                      key={index}
                      $active={index === activeCandidateIndex}
                      onClick={() => setActiveCandidateIndex(index)}
                    >
                      <CandidateName
                        $active={index === activeCandidateIndex}
                        $isViewed={viewedCandidatesIds.includes(
                          candidate[
                            isCreatedDraftCampaign ? "externalId" : "id"
                          ]
                        )}
                      >
                        <div>
                          <GeneratedCandidateFlag
                            $isViewed={viewedCandidatesIds.includes(
                              candidate[
                                isCreatedDraftCampaign ? "externalId" : "id"
                              ]
                            )}
                            src="/images/generated-contact-icon.png"
                          />

                          <span>
                            {candidate.full_name ||
                              [candidate.first_name, candidate.last_name]
                                .filter(Boolean)
                                .join(" ") ||
                              candidate.email ||
                              "-"}
                          </span>
                        </div>
                        {index === activeCandidateIndex &&
                          isCreatedDraftCampaign && (
                            <ActiveTabArrow src="/images/active-email-tab-arrow.png" />
                          )}
                      </CandidateName>
                    </CandidateTabTitle>
                  )
                );
              })}
            </CandidateTabsBlock>
          </LeftSidePanel>
          <RightSidePanel $isCreatedDraftCampaign={isCreatedDraftCampaign}>
            <ContentHeader>
              <ContentHeaderInfo>
                <ContentHeaderTitleBlock>
                  <ContentHeaderTitle $active={true}>
                    {filteredCandidates[activeCandidateIndex].full_name ||
                      [
                        filteredCandidates[activeCandidateIndex].first_name,
                        filteredCandidates[activeCandidateIndex].last_name,
                      ]
                        .filter(Boolean)
                        .join(" ") ||
                      filteredCandidates[activeCandidateIndex].email ||
                      "-"}
                  </ContentHeaderTitle>
                  <ContentHeaderTitle $active={false}>
                    {filteredCandidates[activeCandidateIndex].email}
                  </ContentHeaderTitle>
                </ContentHeaderTitleBlock>
                {currentSequence?.length > 1 && (
                  <CustomSelect
                    title=""
                    options={currentSequence}
                    selected={currentMessageAttempt}
                    onSelect={handleSelectMessage}
                    minWidth="158px"
                    disabled={
                      isLoading ||
                      (!!campaign.isNeedToGenerateMessagePreviews &&
                        !filteredCandidates[activeCandidateIndex]
                          .isNeedToPrepareAllMessages)
                    }
                  />
                )}
              </ContentHeaderInfo>

              <ActionsButtons $isCreatedDraftCampaign={isCreatedDraftCampaign}>
                <div>
                  {activeContent === CONTENT_TYPES.EMAIL && (
                    <>
                      {!isEditing ? (
                        <EditBtn
                          $marginLeft="none"
                          $disabled={isLoading || isLoadFailed}
                          onClick={() => setIsEditing(true)}
                        >
                          Edit
                        </EditBtn>
                      ) : (
                        <>
                          <EditBtn $marginLeft="none" onClick={editMessage}>
                            Save
                          </EditBtn>
                          <EditBtn onClick={() => setIsEditing(false)}>
                            Cancel
                          </EditBtn>
                        </>
                      )}
                      <RegenerateBtn
                        $disabled={
                          isLoading ||
                          isLoadFailed ||
                          (!!campaign.isNeedToGenerateMessagePreviews &&
                            !filteredCandidates[activeCandidateIndex]
                              ?.isNeedToPrepareAllMessages)
                        }
                        onClick={regenerateMessage}
                      >
                        Regenerate Text
                      </RegenerateBtn>
                    </>
                  )}
                </div>
                {isAdmin() && (
                  <ChangeContentTypeBtn
                    onClick={toggleActiveContent}
                    $disabled={isLoading || isLoadFailed}
                  >
                    {activeContent === CONTENT_TYPES.EMAIL
                      ? "View Prompt"
                      : `View ${
                          currentMessageType === "sms" ? "SMS" : "Email"
                        }`}
                  </ChangeContentTypeBtn>
                )}
              </ActionsButtons>
            </ContentHeader>
            {activeContent === CONTENT_TYPES.PROMPT ? (
              <>
                <ContentTitle>Prompt:</ContentTitle>
                <ContentBody
                  $height="calc(100vh - 262px)"
                  $overflow="auto"
                  $margin="5px 0 0 0"
                >
                  <ContentBodyText>
                    {buildPromptSequence(currentPrompt)}
                  </ContentBodyText>
                </ContentBody>
              </>
            ) : isLoading ? (
              <Loader
                parentSize={true}
                padding="0 30px 0 0"
                height="calc(100vh - 220px)"
                text={`Just a moment.  We're ${
                  isEditing
                    ? "saving"
                    : !isRegenerating &&
                      filteredCandidates[activeCandidateIndex]?.hasMessage
                    ? "loading"
                    : "generating"
                } the message...`}
              />
            ) : isLoadFailed ? (
              <ErrorMessage>Could not load message</ErrorMessage>
            ) : isEditing ? (
              <EditForm>
                {currentMessageType !== "sms" && (
                  <>
                    <ContentTitle>Subject:</ContentTitle>
                    <ContentBodyText>
                      <SubjectInputBlock
                        $padding="10px 10px 0 30px"
                        $margin="5px 0 0 0"
                        $height="fit-content"
                      >
                        <EditingInput
                          ref={textareaRef}
                          type="text"
                          value={updatedMessageSubject}
                          onInput={handleInput}
                        />
                      </SubjectInputBlock>
                    </ContentBodyText>
                  </>
                )}
                <InputTextarea
                  id="email-text"
                  value={updatedMessageText}
                  onChange={setUpdatedMessageText}
                />
              </EditForm>
            ) : (
              <>
                {currentMessageType !== "sms" && (
                  <>
                    <ContentTitle>Subject:</ContentTitle>
                    <ContentBody
                      $padding="10px 10px 0 30px"
                      $margin="5px 0 0 0"
                    >
                      <ContentBodyText>
                        <p>{currentMessageSubject}</p>
                      </ContentBodyText>
                    </ContentBody>
                  </>
                )}
                <ContentBody $height="calc(100vh - 320px)" $overflow="auto">
                  <ContentBodyText>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: currentMessageText,
                      }}
                      onClick={openPreviewMessageLinkInNewTab}
                    ></div>
                  </ContentBodyText>
                </ContentBody>
              </>
            )}
            {!isCreatedDraftCampaign && (
              <ButtonGroup>
                <StepButton
                  onClick={handleDecreaseStep}
                  disabled={isLoading}
                  isNext={false}
                />

                {campaign.candidatesLength !== 0 && (
                  <Button
                    onClick={(e) => startGenerateAll(e)}
                    disabled={isLoading}
                  >
                    Generate All
                  </Button>
                )}
                <LaunchCampaignBtn
                  onClick={finishWithCreditsCheck}
                  $width={"200px"}
                  disabled={
                    campaign.candidatesLength === 0 ||
                    isLoading ||
                    isCreatingCampaign
                  }
                >
                  Launch Campaign
                </LaunchCampaignBtn>
              </ButtonGroup>
            )}
          </RightSidePanel>
        </PreviewContent>
      </Content>
    </PreviewContainer>
  );
}

export default PreviewMessage;

const PreviewContainer = styled.div`
  ${({ $isCreatedDraftCampaign }) =>
    $isCreatedDraftCampaign ? "position: fixed;" : ""}
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  margin: 0 0 0 auto;
  height: 100%;
  background: #fff;
  box-shadow: ${({ $isCreatedDraftCampaign }) =>
    $isCreatedDraftCampaign ? "-4px 0 10px rgba(0, 0, 0, 0.2)" : "none"};
  border: ${({ $isCreatedDraftCampaign }) =>
    $isCreatedDraftCampaign ? "1px solid #999" : "none"};
  overflow: hidden;
`;

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px 40px 40px;
  background-color: ${({ $isCreatedDraftCampaign }) =>
    $isCreatedDraftCampaign ? "#fff" : "#f5f2ee"};
  border-bottom: 1px solid #e1e1e1;
  height: ${({ $isCreatedDraftCampaign }) =>
    $isCreatedDraftCampaign ? "130px" : "85px"};
`;

const CloseIcon = styled.img`
  cursor: pointer;
  width: 29px;
  height: 20px;
  margin-bottom: 20px;
`;

const PreviewHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  font-size: ${({ $isCreatedDraftCampaign }) =>
    $isCreatedDraftCampaign ? "24px" : "18px"};
  font-weight: 800;
  font-family: "AlbertSansExtraBold", sans-serif;
  color: ${({ $isCreatedDraftCampaign }) =>
    $isCreatedDraftCampaign ? "#888888" : "#000000"};
  margin-bottom: 0;
`;

const PreviewHeaderSubTitle = styled.div`
  span {
    font-size: 14px;
    font-weight: 400;
    font-family: "AlbertSansThin", sans-serif;
    color: #000000;
  }
`;

const PreviewContent = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

const LeftSidePanel = styled.div`
  width: 25%;
  height: 100%;
  background-color: ${({ $isCreatedDraftCampaign }) =>
    $isCreatedDraftCampaign ? "#f8f8f8" : "#f5f2ee"};
  border: 1px solid #e1e1e1;
`;

const CandidateTabsBlock = styled.div`
  height: calc(100% - 200px);
  overflow: auto;
  margin-right: 20px;
`;

const CandidateTabTitle = styled.div`
  padding-left: 20px;
  cursor: pointer;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CandidateName = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ $active, $isViewed }) =>
    $active ? "#000000" : $isViewed ? "#F95959" : "#9F9F9F"};
  font-weight: ${({ $active }) => ($active ? "700" : "400")};
  font-family: "AlbertSansThin", sans-serif;
  font-size: 12px;
  border-bottom: ${({ $active }) => ($active ? "1px solid #E3E3E3" : "")};
  div {
    display: flex;
    align-items: center;
    span {
      margin-left: ${({ $isViewed }) => ($isViewed ? "0" : "23px")};
    }
  }
`;

const ActiveTabArrow = styled.img`
  width: 7px;
  margin-right: 10px;
`;

const GeneratedCandidateFlag = styled.img`
  ${({ $isViewed }) => !$isViewed && "display: none;"}
  width: 13px;
  margin-right: 10px;
`;

const RightSidePanel = styled.div`
  width: 75%;
  height: 100%;
  background-color: ${({ $isCreatedDraftCampaign }) =>
    $isCreatedDraftCampaign ? "#f9f9f9" : "#f5f2ee"};
  padding: 20px 50px 60px 50px;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const ContentHeaderInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
`;

const ContentHeaderTitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  + div {
    margin: 0;
    color: rgba(0, 0, 0, 0.7);
  }
`;

const MessageLabel = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 9px;
  align-items: center;
  justify-content: flex-start;
`;

const ContentHeaderTitle = styled.div`
  font-family: ${({ $active }) =>
    $active ? "'AlbertSansExtraBold', sans-serif" : "'AlbertSans', sans-serif"};
  font-size: ${({ $active }) => ($active ? "16px" : "11px")};
  font-weight: ${({ $active }) => ($active ? "800" : "400")};
  color: #000000;
  margin-bottom: 5px;
`;

const ContentBody = styled.div`
  background-color: #ffffff;
  margin: ${({ $margin }) => ($margin ? $margin : "20px 0 0 0")};
  width: auto;
  height: ${({ $height }) => ($height ? $height : "auto")};
  border: 1px solid #dcdbd9;
  padding: ${({ $padding }) => ($padding ? $padding : "30px 0 30px 30px")};
  font-size: 14px;
  border-radius: 5px;
  overflow: ${({ $overflow }) => ($overflow ? $overflow : "")};
`;

const ContentBodyText = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: ${({ $height }) => ($height ? $height : "auto")};
  color: #7d7c7b;
  font-size: 13px;
  word-wrap: break-word;
  p {
    padding-bottom: 10px;
  }
`;

const ContentTitle = styled.div`
  color: #7d7c7b;
  font-size: 13px;
  margin-top: 10px;
`;

const Search = styled.div`
  position: relative;
  margin: 15px 20px 0 20px;
`;

const SearchInput = styled.input`
  height: 35px;
  width: 100%;
  padding-left: 30px;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  box-shadow: none;
  &:focus-visible {
    height: 35px;
    width: 100%;
    padding-left: 30px;
    border-radius: 5px;
    border: 1px solid #ebebeb;
    box-shadow: none;
  }
`;

const SearchIcon = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 13px;
  height: 13px;
`;

const RegenerateBtn = styled.button`
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  color: ${({ $disabled }) => ($disabled ? "#aca9a7" : "#5a5a5a")};
  background-color: transparent;
  height: 40px;
  width: 135px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 6px;
  border: ${({ $disabled }) =>
    $disabled ? "1px solid #aca9a7" : "1px solid #5a5a5a"};
  margin-left: 10px;
`;

const EditForm = styled.div`
  height: calc(100vh - 230px);
`;

const EditBtn = styled.button`
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  color: ${({ $disabled }) => ($disabled ? "#aca9a7" : "#5a5a5a")};
  background-color: transparent;
  height: 40px;
  width: 61px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 6px;
  border: ${({ $disabled }) =>
    $disabled ? "1px solid #aca9a7" : "1px solid #5a5a5a"};
  margin-left: ${({ $marginLeft }) => ($marginLeft ? $marginLeft : "10px")};
`;

const ActionsButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: ${({ $isCreatedDraftCampaign }) =>
    $isCreatedDraftCampaign ? "space-between" : "flex-end"};
`;

const SubjectInputBlock = styled.div`
  display: flex;
  background-color: #ffffff;
  margin: ${({ $margin }) => ($margin ? $margin : "20px 0 0 0")};
  width: auto;
  height: ${({ $height }) => ($height ? $height : "auto")};
  border: 1px solid #dcdbd9;
  padding: ${({ $padding }) => ($padding ? $padding : "30px 0 30px 30px")};
  font-size: 14px;
  border-radius: 5px;
  ${({ $overflow }) => ($overflow ? $overflow : "")}
`;

const EditingInput = styled.textarea`
  margin-bottom: 10px;
  font-size: 13px;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  height: 19px;
  width: 100%;
  outline: none;
  resize: none;
  box-shadow: none;

  &:focus-visible {
    margin-bottom: 10px;
    font-size: 13px;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    height: 19px;
    width: 100%;
    outline: none;
    resize: none;
    box-shadow: none;
  }
`;

const InputTextarea = styled(ReactQuill)`
  margin-bottom: 5px;
  margin-top: 15px;
  width: 100%;
  background-color: white;
  height: calc(100vh - 320px);
  .ql-container {
    overflow: auto;
    height: calc(100% - 43px);
    .ql-editor {
      padding-left: 30px;
      p {
        padding-bottom: 10px;
      }
    }
  }
`;

const Button = styled.button`
  cursor: pointer;
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.colors.divider_color : theme.colors.gray};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.divider_color : theme.colors.gray};
  border-radius: 6px;
  height: 40px;
  width: 158px;
  background-color: transparent;
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 12px;
  font-weight: 700;
`;

const ButtonGroup = styled.div`
  grid-row-start: 2;
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  align-items: end;
  margin-top: 20px;
`;

const LaunchCampaignBtn = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: white;
  border-radius: 5px;
  height: 40px;
  width: ${({ $width }) => ($width ? $width : "120px")};
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? "rgb(71,17,104,0.75)" : theme.colors.saturated_purple};
  background-color: ${({ disabled, theme }) =>
    disabled ? "rgb(71,17,104,0.75)" : theme.colors.saturated_purple};
  font-weight: 700;
  font-size: 12px;
  font-family: "AlbertSansExtraBold", sans-serif;
`;

const ChangeContentTypeBtn = styled.button`
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  color: ${({ $disabled }) => ($disabled ? "#aca9a7" : "#5a5a5a")};
  font-size: 12px;
  background-color: transparent;
  height: 40px;
  width: 158px;
  font-weight: 700;
  border-radius: 6px;
  border: ${({ $disabled }) =>
    $disabled ? "1px solid #aca9a7" : "1px solid #5a5a5a"};
  margin-left: 10px;
`;

const PromptItem = styled.div`
  border-bottom: 1px solid #dcdbd9;
  margin: 5px 0;
`;

const PromptRole = styled.div``;

const PromptRoleLabel = styled.span`
  font-weight: bold;
  font-style: italic;
  margin-right: 5px;
`;

const PromptRoleValue = styled.span``;

const PromptContent = styled.div``;

const PromptContentLabel = styled.div`
  font-weight: bold;
  font-style: italic;
`;

const PromptContentValue = styled.div`
  margin: 5px 0;
`;

const ErrorMessage = styled.div`
  width: 100%;
  height: calc(100% - 108px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #de6363;
  font-size: 11px;
`;
