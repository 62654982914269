import React, { forwardRef } from "react";

import TabsList from "./TabsList";
import { Container } from "./styles";

const Tabs = forwardRef(({ selectedOptionId, handleSelectOption }, ref) => {
  return (
    <Container ref={ref}>
      <TabsList
        selectedTabId={selectedOptionId}
        handleSelectTab={handleSelectOption}
      />
    </Container>
  );
});

export default Tabs;
