import {
  fetchLogin,
  fetchCheckEmailForSignUp,
  fetchSignUp,
  fetchSignUpAndCreatePaymentIntent,
  fetchConfirmPaymentIntentForSignUp,
  fetchContactUs,
  fetchGetIntegrations,
  fetchAskResetPassword,
  fetchResetPassword,
  fetchGetMinExtensionVersion,
  fetchCreateIntegration,
  fetchOauthUserData,
} from "./queries.js";
import { sendMessageToExtension } from "../utils/postToExtension.js";
import {
  saveDataToLocalStorage,
  saveObjectToLocalStorage,
} from "../api/localStorage.js";
import { saveLastLoginMethod } from "../utils/utils.js";

const logIn = async (data) => {
  const result = await fetchLogin(data);

  return await handleLogin(result, data);
};

const handleLogin = async (result, loginData) => {
  if (result.message) {
    return { success: false, message: result.message };
  }

  const accessToken = result.token;
  const refreshToken = result.refreshToken;
  const role = result.user.role;
  const email = result.user.email;
  const name = result.user.name;
  const companyId = result.user.companyId;
  const avatar = result.user.avatar;
  const oauthType = result.user.oauthType;
  const postSignUpOnboarding = result.user.postSignUpOnboarding;
  const company = result.company;
  const hasCreatedCampaign = result.user.hasCreatedCampaign;

  console.log("adding to local storage");

  saveDataToLocalStorage("accessToken", accessToken);
  saveDataToLocalStorage("refreshToken", refreshToken);
  saveObjectToLocalStorage("user", {
    role,
    email,
    name,
    companyId,
    avatar,
    postSignUpOnboarding,
    id: result.user.id,
    oauthType,
    hasCreatedCampaign,
  });
  if (company) {
    saveObjectToLocalStorage("companyInfo", { company });
  }

  saveLastLoginMethod(loginData?.oauthType || "");

  const integrations = await fetchGetIntegrations();

  console.log("sending message to extension");

  sendMessageToExtension({
    message: "logged-in",
    data: {
      accessToken,
      refreshToken,
      email,
      role,
      isOpenCampaigns: true,
    },
  });

  sendMessageToExtension({
    message: "save-integrations",
    data: {
      integrations,
    },
  });

  return { success: true };
};

const afterSuccessSignUp = async (result) => {
  const accessToken = result.token;
  const refreshToken = result.refreshToken;
  const role = result.role;
  const email = result.email;
  const name = result.name;
  const company = result.company;
  const avatar = result.avatar;
  const oauthType = result.oauthType;
  const postSignUpOnboarding = result.postSignUpOnboarding;
  const hubspotIntegrationData = result.hubspotIntegrationData;

  console.log("adding to local storage");

  saveDataToLocalStorage("accessToken", accessToken);
  saveDataToLocalStorage("refreshToken", refreshToken);
  saveObjectToLocalStorage("companyInfo", { company });
  saveObjectToLocalStorage("user", {
    role,
    email,
    name,
    avatar,
    postSignUpOnboarding,
    companyId: company.id,
    id: result.id,
    oauthType,
  });

  saveLastLoginMethod(oauthType);

  console.log("sending message to extension");

  if (role === "user") {
    const integrations = await fetchGetIntegrations();

    sendMessageToExtension({
      message: "save-integrations",
      data: {
        integrations,
      },
    });
  }

  const isCreateHubspotIntegrationViaPublicApp =
    hubspotIntegrationData && role === "company_admin";
  let isCreatedHubspotIntegrationViaPublicApp = false;
  if (isCreateHubspotIntegrationViaPublicApp) {
    const result = await fetchCreateIntegration(hubspotIntegrationData);

    if (result && result.id) {
      isCreatedHubspotIntegrationViaPublicApp = true;
      const integrations = await fetchGetIntegrations();

      sendMessageToExtension({
        message: "save-integrations",
        data: {
          integrations,
        },
      });
    } else {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });
    }
  }

  sendMessageToExtension({
    message: "logged-in",
    data: {
      accessToken,
      refreshToken,
      email: result.email,
      role,
      isOpenCampaigns: role === "company_admin" ? false : true,
      isCreatedHubspotIntegrationViaPublicApp,
    },
  });

  return { success: true, role };
};

const checkEmailForSignUp = async (email) => {
  const result = await fetchCheckEmailForSignUp(email);

  if (result.message) {
    return { success: false, message: result.message };
  }

  return { success: true };
};

const signUp = async (data) => {
  const { user, email, pwd, code, oauthType, hubspotIntegrationData } = data;
  const result = await fetchSignUp({
    name: user,
    email,
    password: pwd,
    code,
    oauthType,
  });

  if (result.message) {
    return { success: false, message: result.message };
  }

  if (hubspotIntegrationData) {
    result.hubspotIntegrationData = hubspotIntegrationData;
  }

  return await afterSuccessSignUp(result);
};

const signUpAndCreatePaymentIntent = async (data) => {
  const {
    name,
    email,
    password,
    company,
    planId,
    planPeriod,
    hubspotIntegrationData,
    oauthType,
  } = data;

  const currentTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const result = await fetchSignUpAndCreatePaymentIntent({
    name,
    email,
    password,
    company,
    planId,
    planPeriod,
    oauthType,
    userTimeZone: currentTz,
  });

  if (result.message) {
    return { success: false, message: result.message };
  }

  if (hubspotIntegrationData) {
    result.hubspotIntegrationData = hubspotIntegrationData;
  }

  if (result.clientSecret) {
    return { success: true, ...result };
  }

  return await afterSuccessSignUp(result);
};

const confirmPaymentIntentForSignUp = async (data) => {
  const result = await fetchConfirmPaymentIntentForSignUp(data);

  if (result.message) {
    return { success: false, message: result.message };
  }

  return await afterSuccessSignUp(result);
};

const contactUs = async (data) => {
  const result = await fetchContactUs(data);

  if (result.message) {
    return { success: false, message: result.message };
  }

  return { success: true };
};

const askResetPassword = async (data) => {
  const result = await fetchAskResetPassword(data);

  if (result.message) {
    return { success: false, message: result.message };
  }

  return { success: true };
};

const resetPassword = async (data) => {
  const result = await fetchResetPassword(data);

  if (result.message) {
    return { success: false, message: result.message };
  }

  const accessToken = result.token;
  const refreshToken = result.refreshToken;
  const role = result.user.role;
  const email = result.user.email;
  const name = result.user.name;
  const companyId = result.user.companyId;
  const avatar = result.user.avatar;
  const oauthType = result.user.oauthType;
  const postSignUpOnboarding = result.user.postSignUpOnboarding;
  const company = result.company;
  const hasCreatedCampaign = result.user.hasCreatedCampaign;

  console.log("adding to local storage");

  saveDataToLocalStorage("accessToken", accessToken);
  saveDataToLocalStorage("refreshToken", refreshToken);
  saveObjectToLocalStorage("user", {
    role,
    email,
    name,
    companyId,
    avatar,
    postSignUpOnboarding,
    id: result.user.id,
    oauthType,
    hasCreatedCampaign,
  });
  if (company) {
    saveObjectToLocalStorage("companyInfo", { company });
  }

  saveLastLoginMethod(oauthType);

  const integrations = await fetchGetIntegrations();

  console.log("sending message to extension");

  sendMessageToExtension({
    message: "logged-in",
    data: {
      accessToken,
      refreshToken,
      email,
      role,
      isOpenCampaigns: true,
    },
  });

  sendMessageToExtension({
    message: "save-integrations",
    data: {
      integrations,
    },
  });

  return { success: true };
};

const getMinExtensionVersion = async () => {
  try {
    const result = await fetchGetMinExtensionVersion();

    if (result.message) {
      return result;
    }
    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Failed to get min extension version" };
  }
};

const getOauthUserData = async (data) => {
  const result = await fetchOauthUserData(data);

  if (result.message) {
    return { success: false, message: result.message };
  }

  return { success: true, result: result };
};

export {
  logIn,
  checkEmailForSignUp,
  signUp,
  signUpAndCreatePaymentIntent,
  confirmPaymentIntentForSignUp,
  contactUs,
  askResetPassword,
  resetPassword,
  getMinExtensionVersion,
  getOauthUserData,
};
