import React from "react";
import {
  Container,
  Credits,
  Description,
  IconContainer,
  TextHighlight,
  TextHighlightSm,
  Hellip,
  InfoTooltip,
  CreditsAllocatedValue,
  CreditsAllocatedText,
  CreditsUsedBar,
  CreditsUsedText,
  SubscriptionPlan,
  UpgradePlanContainer,
  UpgradePlanBtn,
} from "./styles";
import {
  getDataFromLocalStorage,
  getObjectFromLocalStorage,
} from "../../../../api/localStorage";
import { useNavigate } from "react-router-dom";
import { sendMessageToExtension } from "../../../../utils/postToExtension";
import getUserFromLocalStorage from "../../../../utils/getUserFromLocalStorage";
import { USER_ROLES } from "../../../../utils/constants";

function EstimatedTokenCostCard(props) {
  const navigate = useNavigate();

  const { isLoading, estimatedToken } = props;
  const creditsRemaining = Math.max(props.creditsRemaining, 0);

  const userInfo = getUserFromLocalStorage();
  const companyInfo = getObjectFromLocalStorage("companyInfo");

  const subscriptionPlanName = companyInfo?.company?.subscriptionPlanName;
  const subscriptionPeriodEnd = new Date(
    companyInfo.company?.subscriptionPeriodEnd || Date.now()
  ).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const creditsPerMonth = Math.round(
    companyInfo?.company?.creditsPerMonth / 1000
  );
  const creditsUsedPart = !creditsPerMonth
    ? 1
    : 1 - creditsRemaining / creditsPerMonth;

  const handleClickUpgradePlan = (event) => {
    event.preventDefault();

    const extensionId = getDataFromLocalStorage("extensionId") || "";
    const extensionVersion = getDataFromLocalStorage("extensionVersion") || "";

    navigate(
      `/settings?extensionId=${extensionId}&extensionVersion=${extensionVersion}&isSubscription=true&from=campaign-creation`,
      { state: { from: "campaign-creation" } }
    );

    sendMessageToExtension({
      message: "change-iframe-window-type",
      data: {
        iframeWindowType: "drawer",
      },
    });
  };

  return (
    <Container>
      {isLoading ? (
        <Hellip>&hellip;</Hellip>
      ) : (
        <Credits>
          <TextHighlight>{estimatedToken.toLocaleString("en")}</TextHighlight>
          <TextHighlightSm>
            /{creditsRemaining.toLocaleString("en")}
          </TextHighlightSm>{" "}
          credits
        </Credits>
      )}
      <Description>
        The credit estimate is based on
        <br />
        your campaign parameters.
      </Description>
      <IconContainer id="outreach-credits-info" $disabled={isLoading}>
        i
      </IconContainer>
      <InfoTooltip
        anchorSelect="#outreach-credits-info"
        clickable
        place="bottom"
      >
        <CreditsAllocatedValue>
          {creditsPerMonth.toLocaleString("en")}
        </CreditsAllocatedValue>
        <CreditsAllocatedText>Credits Allocated</CreditsAllocatedText>
        <CreditsUsedBar $value={creditsUsedPart * 100}>
          <div />
        </CreditsUsedBar>
        <CreditsUsedText $withSubscription={!!subscriptionPlanName}>
          {subscriptionPlanName ? (
            <>
              You are on the{" "}
              <SubscriptionPlan>{subscriptionPlanName} plan</SubscriptionPlan>{" "}
              until {subscriptionPeriodEnd}, and have already used
            </>
          ) : (
            "You have already used"
          )}{" "}
          {creditsUsedPart
            .toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })
            .replace(".00%", "%")}{" "}
          of the credits!
        </CreditsUsedText>
        {!!subscriptionPlanName &&
          (userInfo?.role === USER_ROLES.ADMIN ||
            userInfo?.role === USER_ROLES.COMPANY_ADMIN) && (
            <UpgradePlanContainer>
              <UpgradePlanBtn onClick={handleClickUpgradePlan}>
                Upgrade Now
              </UpgradePlanBtn>
            </UpgradePlanContainer>
          )}
      </InfoTooltip>
    </Container>
  );
}

export default EstimatedTokenCostCard;
