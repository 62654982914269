import { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import Loader from "../Loader";
import ConnectBtn from "../ConnectBtn";
import HubspotFieldsPopup from "../HubspotFieldsPopup";
import ConfirmPopup from "../ConfirmPopup";

import {
  createIntegration,
  getHubspotFields,
} from "../../services/integrations";
import getUserFromLocalStorage from "../../utils/getUserFromLocalStorage";
import { sendMessageToExtension } from "../../utils/postToExtension";
import { displayIntegrationDate } from "./utils";
import ConfirmKeyGen from "./ConfirmKeyGen";
import WizardStepControls from "./WizardStepControls";
import {
  COOKIE_OPTIONS_STRING,
  CRMS,
  HUBSPOT_APP_INSTALL_URL,
  SOURCE_QUERY_PARAM,
  SOURCE_QUERY_VALUE,
  TEMP_LOCAL_STORAGE_KEY_NAME_COOKIE_NAME,
  TEMP_LOCAL_STORAGE_KEY_NAME_LIST_COOKIE_NAME,
} from "../../utils/constants";

const CRMs = {
  bullhorn: {
    iconSrc: "bullhorn-icon.png",
    tabIconSrc: "bullhorn-icon.png",
    hoverIconSrc: "bullhorn-icon.png",
    value: CRMS.BULLHORN,
    description:
      "Reach is compatible with Bullhorn CRM. Provide your API keys, and you're done.",
  },
  hubspot: {
    iconSrc: "hubspot-icon.png",
    tabIconSrc: "hubspot-icon.png",
    hoverIconSrc: "hubspot-icon.png",
    value: CRMS.HUBSPOT,
    description: "Reach is compatible with HubSpot CRM.",
  },
  salesforce: {
    iconSrc: "salesforce-icon.png",
    tabIconSrc: "salesforce-icon.png",
    hoverIconSrc: "salesforce-icon.png",
    value: "salesforce",
    description: "",
    disabled: true,
  },
};

const SetCRM = ({
  onConnected,
  isWizard,
  goToTab,
  dataToSet,
  connectionFields,
  onDisconnect,
  stepConfig,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFieldsPopupOpened, setIsFieldsPopupOpened] = useState(false);
  const [connectionInfo, setConnectionInfo] = useState(connectionFields);

  const [activeProvider, setActiveProvider] = useState(dataToSet?.type || null);
  const [connectedProviders, setConnectedProviders] = useState(
    dataToSet || (connectionFields && Object.keys(connectionFields).length > 0)
      ? [
          ...Object.keys(connectionFields || {}),
          ...(dataToSet &&
          (!connectionFields ||
            !Object.keys(connectionFields).includes(dataToSet.type))
            ? [dataToSet.type]
            : []),
        ]
      : null
  );

  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [accessToken, setAccessToken] = useState(dataToSet?.accessToken || "");

  const [fieldsConfig, setFieldsConfig] = useState(null);

  const [isReconnecting, setIsReconnecting] = useState(!!dataToSet);
  const [isConfirmDisconnectPopupOpen, setIsConfirmDisconnectPopupOpen] =
    useState(false);
  const [isConfirmKeyGen, setIsConfirmKeyGen] = useState(false);
  const [disconnectingProvider, setDisconnectingProvider] = useState(null);

  const handleProviderClick = (key) => {
    setIsReconnecting(true);
    setActiveProvider(key);
  };

  const isCompanyAdmin = () => {
    const user = getUserFromLocalStorage();
    return user?.role === "company_admin" || user?.role === "admin";
  };

  const onCloseProviderSettings = () => {
    setActiveProvider(null);
    setConnectedProviders(
      connectionFields && Object.keys(connectionFields).length > 0
        ? Object.keys(connectionFields)
        : null
    );

    setClientId("");
    setClientSecret("");
    setUsername("");
    setPassword("");
    setAccessToken("");

    setIsReconnecting(false);
  };

  const handleEditIntegration = (provider) => {
    setActiveProvider(provider);
    setIsReconnecting(true);
  };

  const handleDisconnect = async (provider) => {
    setIsConfirmDisconnectPopupOpen(false);
    setActiveProvider(null);
    setDisconnectingProvider(null);
    const success = !onDisconnect || (await onDisconnect(provider));
    if (success) {
      setIsReconnecting(false);
      const newData = { ...connectionInfo };
      delete newData[provider];
      setConnectionInfo(newData);
      setConnectedProviders(Object.keys(newData));
      if (provider === CRMs.hubspot.value) {
        setFieldsConfig(null);
      }
    }
  };

  const handleConnect = async (e) => {
    e.preventDefault();

    if (!isCompanyAdmin()) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "These values can be set only by company admin",
        },
      });
      return;
    }

    const connectionKeys =
      activeProvider === CRMs.bullhorn.value
        ? {
            clientId,
            clientSecret,
            username,
            password,
          }
        : {
            accessToken,
          };

    const data = {
      type: "crm",
      connectionKeys: {
        type: activeProvider,
        ...connectionKeys,
      },
    };

    setIsLoading(true);
    const result = await createIntegration(data);

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });
      setIsLoading(false);
      return;
    }

    const newData = { ...connectionInfo };
    newData[activeProvider] = {};

    Object.keys(data.connectionKeys).forEach((key) => {
      newData[activeProvider][key] =
        key === "type"
          ? data.connectionKeys[key]
          : data.connectionKeys[key].replace(/[^-]/g, "*");
    });

    newData[activeProvider].expired = false;
    newData[activeProvider].type = activeProvider;
    newData[activeProvider].createdAt = new Date().toISOString();

    setConnectionInfo(newData);
    setClientId("");
    setClientSecret("");
    setUsername("");
    setPassword("");
    setAccessToken("");

    setConnectedProviders(Object.keys(newData));
    setIsReconnecting(false);
    setIsLoading(false);
    onConnected("crm");

    if (activeProvider === CRMs.hubspot.value) {
      fetchHubspotFields();
    }
  };

  const fetchHubspotFields = async () => {
    setIsLoading(true);
    const response = await getHubspotFields();

    if (!response.success) {
      setIsLoading(false);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: response.message,
        },
      });
      return;
    }

    setFieldsConfig(response.data.fieldsConfig);
    setIsLoading(false);
  };

  const availableProvidersIcons = () => {
    if (connectedProviders && !isReconnecting)
      return Object.keys(CRMs).filter(
        (key) => !connectedProviders.includes(key) && !CRMs[key].disabled
      );
    return [];
  };

  const openDisconnectPopup = (provider) => {
    setIsConfirmDisconnectPopupOpen(true);
    setDisconnectingProvider(provider);
  };

  useEffect(() => {
    if (
      activeProvider === CRMs.hubspot.value &&
      connectionFields &&
      connectionFields[activeProvider]?.expired === false
    ) {
      fetchHubspotFields();
    }
  }, [activeProvider, connectionFields]);

  const handleClickInstallApp = () => {
    const localStorageKeyNameList = [];

    Object.keys(localStorage).forEach((key) => {
      localStorageKeyNameList.push(key);
      const value = localStorage.getItem(key);
      const sanitizedValue = JSON.stringify(value).replace(/;/g, "!!@!!"); // replace ';' with '!!@!!', because some string data may be  contains ';'. And we cant't add it to cookie value.

      // We divide the data as it may have a size more than the browser allows for cookie value(max 4096 bytes).
      document.cookie = `${TEMP_LOCAL_STORAGE_KEY_NAME_COOKIE_NAME}${key}=${sanitizedValue}; ${COOKIE_OPTIONS_STRING}`;
    });

    document.cookie = `${TEMP_LOCAL_STORAGE_KEY_NAME_LIST_COOKIE_NAME}=${JSON.stringify(
      localStorageKeyNameList
    )}; ${COOKIE_OPTIONS_STRING}`;

    window.open(
      `${HUBSPOT_APP_INSTALL_URL}?${SOURCE_QUERY_PARAM}=${SOURCE_QUERY_VALUE}`,
      "_blank"
    );
  };

  return (
    <Section>
      {isLoading ? (
        <Loader parentSize={true} />
      ) : (
        <OverflowContent>
          {isConfirmDisconnectPopupOpen && (
            <ConfirmPopup
              title="Are you sure?"
              description={`You’re about to disconnect ${disconnectingProvider} integration. Are you sure you want to proceed?`}
              onConfirm={() => handleDisconnect(disconnectingProvider)}
              onCancel={() => setIsConfirmDisconnectPopupOpen(false)}
            />
          )}

          {isConfirmKeyGen && (
            <ConfirmKeyGen
              provider={activeProvider}
              titleForGenerate="Generate a HubSpot API key"
              descForGenerate="You must be logged into HubSpot with a Super Admin account for this to work."
              providerLabelForLogin="HubSpot"
              messageTypeForOpenKeyGenWizard="open-automate-hubspot-api-wizard"
              onClose={() => setIsConfirmKeyGen(false)}
            />
          )}

          <Title className="main_title">Connect to your CRM</Title>

          {connectedProviders &&
          connectedProviders.length > 0 &&
          !isReconnecting ? (
            <>
              <PanelDescription>
                Reach is compatible with a number of CRMs. Select the one you
                want to use, provide your API keys, and you're done.
              </PanelDescription>

              {connectedProviders.map((connectedProvider, index) => (
                <ConnectedProviderBlock key={index}>
                  <ProviderIcon
                    $width="106px"
                    src={CRMs[connectedProvider].iconSrc}
                    alt="crm provider"
                  />
                  {connectionInfo[connectedProvider].expired && (
                    <ExpiredWarning>
                      <img
                        width="16"
                        src="/images/warning_icon.png"
                        alt="expired"
                      />
                      Expired
                    </ExpiredWarning>
                  )}
                  {connectionInfo?.createdAt && (
                    <IntegrationDate>
                      Configured&nbsp;
                      <span>
                        {displayIntegrationDate(connectionInfo.createdAt)}
                      </span>
                    </IntegrationDate>
                  )}
                  <div
                    data-tooltip-id={
                      !isCompanyAdmin() ? `no-admin-connected-tooltip` : null
                    }
                    data-tooltip-content="Please contact your company admin to change this setting"
                  >
                    <DisconnectBtn
                      disabled={!isCompanyAdmin()}
                      onClick={() => openDisconnectPopup(connectedProvider)}
                    >
                      Disconnect
                    </DisconnectBtn>
                    <ConnectBtn
                      label="Edit Integration"
                      onClick={() => handleEditIntegration(connectedProvider)}
                      disabled={!isCompanyAdmin()}
                    />
                  </div>
                  <Tooltip
                    id={`no-admin-connected-tooltip`}
                    className="custom-tooltip"
                    place="left"
                  />
                </ConnectedProviderBlock>
              ))}
            </>
          ) : activeProvider && isReconnecting ? (
            <ProviderSetting>
              <ProviderTitleBlock>
                <TitleBlock>
                  <ArrowBackIconBlock onClick={onCloseProviderSettings}>
                    <img
                      src="/images/arrow-campaign-description.svg"
                      alt="arrow-left-icon"
                    />
                  </ArrowBackIconBlock>
                  <ProviderTitle>
                    <div>CRM</div>
                    <span>{CRMs[activeProvider].value}</span>
                  </ProviderTitle>
                </TitleBlock>
                <ProviderIcon
                  src={CRMs[activeProvider].iconSrc}
                  alt="crm provider"
                  width="88"
                />
              </ProviderTitleBlock>
              <ProviderDescription>
                {CRMs[activeProvider].description}
              </ProviderDescription>
              <Content>
                {activeProvider === CRMs.bullhorn.value && (
                  <>
                    <label htmlFor="client-id">Client ID</label>
                    <ApiKeyInput
                      type="text"
                      id="client-id"
                      placeholder={
                        connectionInfo && !isReconnecting
                          ? connectionInfo[activeProvider]?.clientId
                          : "Paste client ID here..."
                      }
                      value={clientId}
                      onChange={(e) => setClientId(e.target.value)}
                      autoComplete="off"
                      required
                    />
                    <label htmlFor="client-secret">Client Secret</label>
                    <ApiKeyInput
                      type="password"
                      id="client-secret"
                      placeholder={
                        connectionInfo && !isReconnecting
                          ? connectionInfo[activeProvider]?.clientSecret
                          : "Paste client secret here..."
                      }
                      value={clientSecret}
                      onChange={(e) => setClientSecret(e.target.value)}
                      autoComplete="off"
                      required
                    />
                    <label htmlFor="username">Username</label>
                    <ApiKeyInput
                      type="text"
                      id="username"
                      placeholder={
                        connectionInfo && !isReconnecting
                          ? connectionInfo[activeProvider]?.username
                          : "Paste username here..."
                      }
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      autoComplete="off"
                      required
                    />
                    <label htmlFor="password">Password</label>
                    <ApiKeyInput
                      type="password"
                      id="password"
                      placeholder={
                        connectionInfo && !isReconnecting
                          ? connectionInfo[activeProvider]?.password
                          : "Paste password here..."
                      }
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="off"
                      required
                    />
                  </>
                )}
                {activeProvider === CRMs.hubspot.value &&
                  fieldsConfig &&
                  Object.keys(fieldsConfig).length > 0 && (
                    <HubspotFieldsContainer>
                      <ReconnectBtn
                        onClick={() => setIsFieldsPopupOpened(true)}
                      >
                        {Object.keys(fieldsConfig).length} relevant properties
                        identified
                      </ReconnectBtn>
                      {isFieldsPopupOpened && (
                        <HubspotFieldsPopup
                          onClose={() => setIsFieldsPopupOpened(false)}
                          fieldsConfig={fieldsConfig}
                          isWizard={isWizard}
                        />
                      )}
                    </HubspotFieldsContainer>
                  )}
                <ActionsBtns>
                  {activeProvider === CRMs.hubspot.value &&
                    (connectionFields?.[activeProvider]?.expired !== false ||
                      connectionFields?.[activeProvider]?.method !==
                        "oauth") && (
                      <InstallHubspotAppLinkButton
                        onClick={handleClickInstallApp}
                      >
                        Install HubSpot App
                      </InstallHubspotAppLinkButton>
                    )}
                  {activeProvider === CRMs.bullhorn.value && (
                    <ConnectBtn
                      label="Connect"
                      onClick={handleConnect}
                      disabled={
                        (activeProvider === CRMs.bullhorn.value &&
                          (clientId === "" ||
                            clientSecret === "" ||
                            username === "" ||
                            password === "")) ||
                        (activeProvider === CRMs.hubspot.value &&
                          accessToken === "")
                      }
                    />
                  )}
                  {(activeProvider !== CRMs.hubspot.value ||
                    connectionFields?.[activeProvider]?.expired !== false ||
                    connectionFields?.[activeProvider]?.method !== "oauth") && (
                    <CancelButton onClick={onCloseProviderSettings}>
                      Cancel
                    </CancelButton>
                  )}
                </ActionsBtns>
              </Content>
            </ProviderSetting>
          ) : (
            <>
              <PanelDescription className="side_panel_description">
                Reach is compatible with a number of CRMs. Select the one you
                want to use, provide your API keys, and you're done.
              </PanelDescription>
              <ProvidersIcons>
                {Object.keys(CRMs).map((key) => (
                  <div key={key}>
                    <ProviderTabBlock
                      $disabled={CRMs[key].disabled}
                      $hoverIconScr={CRMs[key].hoverIconSrc}
                      data-tooltip-id={`${key}-tooltip`}
                      data-tooltip-content={
                        CRMs[key].disabled
                          ? "Coming soon"
                          : !isCompanyAdmin()
                          ? "Please contact your company admin to change this setting"
                          : null
                      }
                      onClick={() =>
                        !CRMs[key].disabled && isCompanyAdmin()
                          ? handleProviderClick(key)
                          : null
                      }
                    >
                      <ProviderIcon src={CRMs[key].tabIconSrc} alt="crm icon" />
                    </ProviderTabBlock>
                    {CRMs[key].disabled || !isCompanyAdmin() ? (
                      <Tooltip
                        id={`${key}-tooltip`}
                        place="right"
                        className="custom-tooltip"
                      />
                    ) : null}
                  </div>
                ))}
              </ProvidersIcons>
            </>
          )}
        </OverflowContent>
      )}

      <div>
        {connectedProviders &&
          connectedProviders.length > 0 &&
          availableProvidersIcons() &&
          availableProvidersIcons().length > 0 && (
            <div>
              <AvailableProvidersDivider />
              <AvailableProvidersTitle>
                Other available integrations for <span>CRMs</span>
              </AvailableProvidersTitle>
              <AvailableProvidersIcons>
                {availableProvidersIcons().map((provider) => (
                  <div key={provider}>
                    <ProviderIcon
                      src={CRMs[provider].iconSrc}
                      alt="crm icon"
                      $cursor="pointer"
                      data-tooltip-id={`${provider}-tooltip`}
                      data-tooltip-content={
                        !isCompanyAdmin()
                          ? "Please contact your company admin to change this setting"
                          : null
                      }
                      onClick={() =>
                        isCompanyAdmin() ? handleProviderClick(provider) : null
                      }
                    />
                    {!isCompanyAdmin() ? (
                      <Tooltip
                        id={`${provider}-tooltip`}
                        place="right"
                        className="custom-tooltip"
                      />
                    ) : null}
                  </div>
                ))}
              </AvailableProvidersIcons>
            </div>
          )}
        {isWizard && (
          <ButtonGroup>
            <WizardStepControls stepConfig={stepConfig} goToTab={goToTab} />
          </ButtonGroup>
        )}
      </div>
    </Section>
  );
};

export default SetCRM;

const Section = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PanelDescription = styled.div`
  font-size: 14px;
  width: 75%;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 24px;
`;

const Title = styled.h3`
  font-family: "AlbertSansExtraBold";
  font-size: 24px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 5px;
`;

const ConnectedProviderBlock = styled.div`
  height: 88px;
  background-color: #f3f3f3;
  margin-right: 110px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 34px;
  border-radius: 5px;
`;

const ExpiredWarning = styled.div`
  font-weight: 700;
  font-size: 12px;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

const DisconnectBtn = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: #5a5a5a;
  background-color: transparent;
  height: 40px;
  padding: 0 30px;
  font-weight: 700;
  font-size: 12px;
  border-radius: 6px;
  border: 1px solid #5a5a5a;
  margin-right: 22px;
`;

const ProviderTitleBlock = styled.div`
  margin-bottom: 13px;
  margin-right: 90px;
  margin-top: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleBlock = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
  display: flex;
  align-items: center;
`;

const ArrowBackIconBlock = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: 40px;
  width: 20px;
  height: 17px;
  &::after {
    content: "";
    cursor: default;
    position: absolute;
    right: -90%;
    top: -50%;
    height: 58px;
    width: 1px;
    background-color: #e6e6e6;
  }
`;

const ProviderTitle = styled.div`
  display: flex;
  flex-direction: column;
  color: #c1c1c1;
  font-size: 12px;
  font-weight: 800;
  span {
    font-size: 24px;
    font-weight: 400;
    color: black;
    text-transform: capitalize;
  }
`;

const ProviderDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
  margin-left: 60px;
  margin-bottom: 24px;
  width: 65%;
`;

const ProviderTabBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88px;
  width: 180px;
  background-color: #f3f3f3;
  border-radius: 5px;
  cursor: pointer;
  img {
    width: 88px;
  }
  &:hover {
    background-color: ${({ $disabled }) => ($disabled ? "#f3f3f3" : "#471168")};

    img {
      content: url("${({ $hoverIconScr }) => $hoverIconScr}");
    }
  }
`;

const ProvidersIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ProviderIcon = styled.img`
  width: ${({ $width }) => ($width ? $width : "88px")};
  cursor: ${({ $cursor }) => ($cursor ? $cursor : "default")};
`;

const ApiKeyInput = styled.input`
  margin-top: 4px;
  margin-bottom: 20px;
  border: 1px solid #d6ddeb;
  padding: 12px 0px 12px 16px;
  height: 38px;
  max-width: 600px;

  &:focus-visible {
    margin-top: 4px;
    margin-bottom: 20px;
    border: 1px solid #d6ddeb;
    padding: 12px 0px 12px 16px;
    height: 38px;
    max-width: 600px;
  }
  &:disabled {
    background-color: white;
  }
`;

const ProviderSetting = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ $width }) => ($width ? $width : "auto")};
  label {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: ${({ theme }) => theme.colors.btn_border_color};
  }
`;

const ButtonGroup = styled.div`
  grid-row-start: 2;
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  align-items: end;
  margin-top: 20px;
`;

const OverflowContent = styled.div`
  overflow: auto;
`;

const ActionsBtns = styled.div`
  display: flex;
  align-items: center;
`;

const ReconnectBtn = styled.div`
  font-size: 14px;
  color: #4640de;
  margin-bottom: 20px;
  cursor: pointer;
`;

const HubspotFieldsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  margin-left: 60px;
  display: flex;
  flex-direction: column;
`;

const AvailableProvidersDivider = styled.div`
  width: 34px;
  height: 4px;
  background-color: black;
  opacity: 0.1;
`;

const AvailableProvidersTitle = styled.div`
  margin-top: 13px;
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
  span {
    font-weight: 700;
    font-family: "AlbertSansExtraBold";
  }
`;

const AvailableProvidersIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
`;

const IntegrationDate = styled.div`
  font-size: 13px;
  font-weight: 400;

  span {
    font-weight: 700;
  }
`;

const CancelButton = styled.div`
  color: #4640de;
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;
  margin-left: 10px;
`;

const InstallHubspotAppLinkButton = styled.button`
  cursor: pointer;
  color: #ffffff;
  background-color: #471168;
  height: 40px;
  width: 138px;
  font-size: 12px;
  font-weight: 800;
  border-radius: 6px;
  border: 1px solid #471168;
`;
