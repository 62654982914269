import React from "react";
import { styled } from "styled-components";
import theme from "../../../theme";
import { CRMS } from "../../../utils/constants";
import { BULLHORN_CAMPAIGN_TYPES } from "../constants";

function CandidateTable({
  candidateList,
  setSort,
  sort,
  handleCheckContact,
  checkedContacts,
  crm,
  campaignType,
}) {
  const isClientContacts =
    crm !== CRMS.BULLHORN ||
    campaignType === BULLHORN_CAMPAIGN_TYPES.marketToClientLeads ||
    campaignType === BULLHORN_CAMPAIGN_TYPES.marketToClientContacts;
  const handleClickSort = (newSort) => {
    setSort({ newSort, newOrder: sort.order === "asc" ? "desc" : "asc" });
  };
  return (
    <>
      <Table>
        <TableHead>
          <TableRow
            $backgroundColor="#f4f3f3"
            $height="30px"
            $borderTop="1px solid #CDCDCD"
          >
            {candidateList.length > 0 && (
              <TableHeadData $padding="0 0 0 8px">
                <Checkbox
                  type="checkbox"
                  name="selectAll"
                  id="select-all-contacts-checkbox"
                  checked={checkedContacts.length === candidateList.length}
                  value={checkedContacts.length === candidateList.length}
                  onChange={() =>
                    checkedContacts.length !== candidateList.length
                      ? handleCheckContact("all")
                      : handleCheckContact("none")
                  }
                />
              </TableHeadData>
            )}
            <TableHeadData
              $padding="0 8px 0 8px"
              $cursorPointer={true}
              onClick={() => handleClickSort("full_name")}
            >
              Name
              <SortIcon
                src={`/images/sort-${
                  sort.sort === "full_name" ? sort.order + "-icon" : "icon"
                }.png`}
                alt="sort asc"
              />
            </TableHeadData>
            <TableHeadData
              $cursorPointer={true}
              onClick={() => handleClickSort("email")}
            >
              Email
              <SortIcon
                src={`/images/sort-${
                  sort.sort === "email" ? sort.order + "-icon" : "icon"
                }.png`}
                alt="sort asc"
              />
            </TableHeadData>
            <TableHeadData
              $cursorPointer={true}
              onClick={() => handleClickSort("phoneNumber")}
            >
              Phone
              <SortIcon
                src={`/images/sort-${
                  sort.sort === "phoneNumber" ? sort.order + "-icon" : "icon"
                }.png`}
                alt="sort asc"
              />
            </TableHeadData>
            {isClientContacts ? (
              <>
                <TableHeadData
                  $cursorPointer={true}
                  onClick={() => handleClickSort("jobTitle")}
                >
                  {crm !== CRMS.BULLHORN ? "Job" : ""} Title
                  <SortIcon
                    src={`/images/sort-${
                      sort.sort === "jobTitle" ? sort.order + "-icon" : "icon"
                    }.png`}
                    alt="sort asc"
                  />
                </TableHeadData>
                <TableHeadData
                  $cursorPointer={true}
                  onClick={() => handleClickSort("company")}
                >
                  Company
                  <SortIcon
                    src={`/images/sort-${
                      sort.sort === "company" ? sort.order + "-icon" : "icon"
                    }.png`}
                    alt="sort asc"
                  />
                </TableHeadData>
              </>
            ) : (
              <>
                <TableHeadData
                  $cursorPointer={true}
                  onClick={() => handleClickSort("category")}
                >
                  Category
                  <SortIcon
                    src={`/images/sort-${
                      sort.sort === "category" ? sort.order + "-icon" : "icon"
                    }.png`}
                    alt="sort asc"
                  />
                </TableHeadData>
                <TableHeadData
                  $cursorPointer={true}
                  onClick={() => handleClickSort("specialty")}
                >
                  Specialty
                  <SortIcon
                    src={`/images/sort-${
                      sort.sort === "specialty" ? sort.order + "-icon" : "icon"
                    }.png`}
                    alt="sort asc"
                  />
                </TableHeadData>
              </>
            )}
            <TableHeadData
              $cursorPointer={true}
              onClick={() => handleClickSort("emailStatus")}
            >
              Email Status
              <SortIcon
                src={`/images/sort-${
                  sort.sort === "emailStatus" ? sort.order + "-icon" : "icon"
                }.png`}
                alt="sort asc"
              />
            </TableHeadData>
          </TableRow>
        </TableHead>
        <TableBody>
          {candidateList.length !== 0 ? (
            candidateList.map((recipient, index) => {
              return (
                <TableRow
                  $isInvalid={recipient.isInvalid}
                  $backgroundColor={recipient.isInvalid ? "#ffe4e4" : ""}
                  $borderBottom={`1px solid ${theme.colors.divider_color}`}
                  key={index}
                >
                  <TableData $padding="0 0 0 8px" color={theme.colors.purple}>
                    <CheckboxBlock>
                      <Checkbox
                        id={`select-contact-${recipient.id}`}
                        type="checkbox"
                        checked={checkedContacts.includes(recipient.id)}
                        value={checkedContacts.includes(recipient.id)}
                        onChange={(event) =>
                          handleCheckContact(event.target.checked, recipient.id)
                        }
                      />
                    </CheckboxBlock>
                  </TableData>
                  <TableData $padding="0 0 0 8px" color={theme.colors.purple}>
                    {recipient.full_name}
                  </TableData>
                  <TableData>{recipient.email}</TableData>
                  <TableData $padding="0 8px 0 0">
                    {recipient.phoneNumber}
                  </TableData>
                  {isClientContacts ? (
                    <>
                      <TableData>{recipient.jobTitle}</TableData>
                      <TableData>{recipient.company}</TableData>
                    </>
                  ) : (
                    <>
                      <TableData>{recipient.category}</TableData>
                      <TableData>{recipient.specialty}</TableData>
                    </>
                  )}
                  <TableData>{recipient.emailStatus}</TableData>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableData $padding="0 0 0 8px">No recipients to show</TableData>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}

export default CandidateTable;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
`;

const TableBody = styled.tbody``;

const TableHead = styled.thead`
  text-align: left;
  color: ${({ theme }) => theme.colors.gray};
  position: sticky;
  top: 0px;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.candidate_table_head_color};
`;

const TableHeadData = styled.th`
  cursor: ${({ $cursorPointer }) => ($cursorPointer ? "pointer" : "")};
  font-size: 11px;
  ${({ $padding }) => ($padding ? `padding: ${$padding}` : "")};
  ${({ $width }) => ($width ? `width: ${$width}` : "")};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.candidate_table_head_color};
`;

const TableRow = styled.tr`
  height: ${({ $height }) => ($height ? $height : "46px")};
  ${({ $backgroundColor, theme }) =>
    $backgroundColor ? `background-color:${$backgroundColor}` : ""};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
  ${({ $borderTop }) => ($borderTop ? `border-top: ${$borderTop}` : "")};
  opacity: ${({ $isInvalid }) => ($isInvalid ? 0.5 : 1)};
`;

const TableData = styled.td`
  ${({ $width }) => ($width ? `width: ${$width}` : "")};
  ${({ $padding }) => ($padding ? `padding: ${$padding}` : "")};
  ${({ color, theme }) => (color ? `color: ${color}` : theme.colors.black)};
  ${({ color, theme }) => (color ? `font-size: 12px` : "font-size:11px")};
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor}` : "")};
`;

const SortIcon = styled.img`
  width: 15px;
  padding-left: 5px;
`;

const CheckboxBlock = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
`;

const Checkbox = styled.input`
  display: block !important;
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin-bottom: 0;
`;
