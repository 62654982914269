import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

function StepButton({ isNext, onClick, disabled = false, label }) {
  return (
    <>
      {isNext ? (
        <NextButton onClick={onClick} disabled={disabled}>
          {label || "Next"}
        </NextButton>
      ) : (
        <PreviousButton onClick={onClick} disabled={disabled}>
          {label || "Previous"}
        </PreviousButton>
      )}
    </>
  );
}

StepButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isNext: PropTypes.bool.isRequired,
};

export default StepButton;

const NextButton = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? "rgb(71,17,104,0.75)" : theme.colors.saturated_purple};
  color: white;
  border-radius: 5px;
  height: 40px;
  width: ${({ $width }) => ($width ? $width : "120px")};
  background-color: ${({ disabled, theme }) =>
    disabled ? "rgb(71,17,104,0.75)" : theme.colors.saturated_purple};
  font-weight: 700;
  font-size: 12px;
  font-family: "AlbertSansExtraBold", sans-serif;
`;

const PreviousButton = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.colors.divider_color : theme.colors.gray};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.divider_color : theme.colors.gray};
  border-radius: 5px;
  height: 40px;
  width: 120px;
  background-color: transparent;
  font-weight: 700;
  font-size: 12px;
  font-family: "AlbertSansExtraBold", sans-serif;
`;
