import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { getUserData, updateUserSignature } from "../../../services/user";
import Loader from "../../Loader";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { sendMessageToExtension } from "../../../utils/postToExtension";

function EmailSignature() {
  const [isLoading, setIsLoading] = useState(true);
  const [signature, setSignature] = useState("");
  const [isUpdatingSignature, setIsUpdatingSignature] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchUserData = async () => {
    const result = await getUserData();
    if (!result.success) {
      setIsLoading(false);
      return;
    }
    if (result.result.signature !== undefined) {
      setSignature(result.result.signature);
    }
    setIsLoading(false);
  };

  const handleUpdateUserSignature = async (event) => {
    event.preventDefault();
    if (signature === "") {
      setErrorMessage("Please enter signature");
      return;
    }
    setIsUpdatingSignature(true);

    const result = await updateUserSignature({ signature });

    if (!result) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });
      setIsUpdatingSignature(false);
      return;
    }

    setIsUpdatingSignature(false);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <Content>
      {isLoading ? (
        <Loader parentSize={true} />
      ) : (
        <Form onSubmit={(event) => handleUpdateUserSignature(event)}>
          <Title>Email Signature</Title>
          <Inputs>
            <InputBlock>
              <Label htmlFor="signature">Signature</Label>
              <Relative>
                <InputTextarea
                  id="signature"
                  placeholder="Enter your signature..."
                  value={signature}
                  onChange={setSignature}
                  onKeyUp={() => setErrorMessage("")}
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, false] }],
                      ["bold", "italic", "underline"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link", "image"],
                      ["clean"],
                    ],
                  }}
                  formats={[
                    "header",
                    "bold",
                    "italic",
                    "underline",
                    "list",
                    "bullet",
                    "link",
                    "image",
                  ]}
                />
                {errorMessage && (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                )}
              </Relative>
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </InputBlock>
          </Inputs>

          <Actions>
            <UpdateSignatureBtn type="submit" disabled={isUpdatingSignature}>
              Update
            </UpdateSignatureBtn>
          </Actions>
        </Form>
      )}
    </Content>
  );
}

export default EmailSignature;

const Content = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 43px 0 43px 63px;
`;

const Inputs = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

const Relative = styled.div`
  position: relative;
`;

const InputTextarea = styled(ReactQuill)`
  height: 150px;
  margin-bottom: 5px;
  width: 90%;
`;

const ErrorIcon = styled.img`
  position: absolute;
  right: 12%;
  top: 24%;
`;

const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
`;

const Title = styled.div`
  font-family: "AlbertSansExtraBold";
  width: 30%;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.gray};
`;

const UpdateSignatureBtn = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: #5a5a5a;
  background-color: transparent;
  height: 40px;
  width: 158px;
  font-weight: 800;
  border-radius: 6px;
  border: 1px solid #5a5a5a;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`;
