import React from "react";
import styled from "styled-components";
import SuggestionPopup from "../components/SuggestionPopup";
import { sendMessageToExtension } from "../utils/postToExtension";

const Alert = () => (
  <Container>
    <SuggestionPopup
      imageSrc="/logo192.png"
      title="We can't find your contacts!"
      description="Please navigate to a browser tab with your CRM or spreadsheet to start a campaign"
      confirmBtnText="Got it!"
      onConfirm={() => {
        sendMessageToExtension({
          message: "close-iframe",
        });
      }}
      removeCancel
      noOverlay
    />
  </Container>
);

export default Alert;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;
