import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
  margin: ${({ $margin }) => ($margin ? $margin : "auto")};
  width: ${({ $width }) => ($width ? `${$width}px` : "auto")};
  height: ${({ $width }) => ($width ? `${$width}px` : "auto")};

  > img {
    width: ${({ $width }) => ($width ? `${$width}px` : "auto")};
  }

  @keyframes slide {
    0% {
      transform: translate(0, 0);
    }
    2% {
      transform: translate(${({ $widthEl }) => $widthEl}px, 0);
    }
    12.5% {
      transform: translate(${({ $widthEl }) => $widthEl}px, 0);
    }
    15.5% {
      transform: translate(${({ $widthEl }) => 2 * $widthEl}px, 0);
    }
    25% {
      transform: translate(${({ $widthEl }) => 2 * $widthEl}px, 0);
    }
    27% {
      transform: translate(
        ${({ $widthEl }) => 2 * $widthEl}px,
        ${({ $widthEl }) => $widthEl}px
      );
    }
    37.5% {
      transform: translate(
        ${({ $widthEl }) => 2 * $widthEl}px,
        ${({ $widthEl }) => $widthEl}px
      );
    }
    39.5% {
      transform: translate(
        ${({ $widthEl }) => $widthEl}px,
        ${({ $widthEl }) => $widthEl}px
      );
    }
    50% {
      transform: translate(
        ${({ $widthEl }) => $widthEl}px,
        ${({ $widthEl }) => $widthEl}px
      );
    }
    52% {
      transform: translate(
        ${({ $widthEl }) => $widthEl}px,
        ${({ $widthEl }) => 2 * $widthEl}px
      );
    }
    62.5% {
      transform: translate(
        ${({ $widthEl }) => $widthEl}px,
        ${({ $widthEl }) => 2 * $widthEl}px
      );
    }
    64.5% {
      transform: translate(0, ${({ $widthEl }) => 2 * $widthEl}px);
    }
    75% {
      transform: translate(0, ${({ $widthEl }) => 2 * $widthEl}px);
    }
    77% {
      transform: translate(0, ${({ $widthEl }) => $widthEl}px);
    }
    87.5% {
      transform: translate(0, ${({ $widthEl }) => $widthEl}px);
    }
    89.5% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes gradient1 {
    from {
      stop-color: #4383b8;
    }
    50% {
      stop-color: #8013b9;
    }
    to {
      stop-color: #4383b8;
    }
  }
  @keyframes gradient2 {
    from {
      stop-color: #4aa06c;
    }
    50% {
      stop-color: #b22358;
    }
    to {
      stop-color: #4aa06c;
    }
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  svg#loading {
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;
  }
  svg#loading #gradient > stop[offset="0%"] {
    animation: gradient1 15s ease 0s infinite;
  }
  svg#loading #gradient > stop[offset="100%"] {
    animation: gradient2 15s ease 0s infinite;
  }
  svg#loading .rect {
    animation: slide 15s ease infinite;
  }
  svg#loading #rect1 {
    animation-delay: 0s;
  }
  svg#loading #rect2 {
    animation-delay: -2.1428571428571s;
  }
  svg#loading #rect3 {
    animation-delay: -4.2857142857143s;
  }
  svg#loading #rect4 {
    animation-delay: -6.4285714285714s;
  }
  svg#loading #rect5 {
    animation-delay: -8.5714285714286s;
  }
  svg#loading #rect6 {
    animation-delay: -10.714285714286s;
  }
  svg#loading #rect7 {
    animation-delay: -12.857142857143s;
  }
`;

const Spinner = ({ width = 90, margin, isNew = true }) => {
  if (isNew) {
    return (
      <Wrap $margin={margin} $width={width > 18 ? width - 15 : width}>
        <img src="/images/bouncing-logo.svg" alt="bouncing-logo" />
      </Wrap>
    );
  }

  const widthInsideEl = width / 3 - 4;
  return (
    <Wrap $margin={margin} $widthEl={width / 3} $width={width}>
      <svg id="loading" viewBox="0 0 100 80">
        <defs>
          <linearGradient id="gradient" x1="100%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#4383b8" />
            <stop offset="100%" stopColor="#4aa06c" />
          </linearGradient>

          <clipPath id="rects">
            <rect
              className="rect"
              id="rect1"
              x="0"
              y="0"
              width={widthInsideEl}
              height={widthInsideEl}
              rx="2"
              ry="2"
            />
            <rect
              className="rect"
              id="rect2"
              x="0"
              y="0"
              width={widthInsideEl}
              height={widthInsideEl}
              rx="2"
              ry="2"
            />
            <rect
              className="rect"
              id="rect3"
              x="0"
              y="0"
              width={widthInsideEl}
              height={widthInsideEl}
              rx="2"
              ry="2"
            />
            <rect
              className="rect"
              id="rect4"
              x="0"
              y="0"
              width={widthInsideEl}
              height={widthInsideEl}
              rx="2"
              ry="2"
            />
            <rect
              className="rect"
              id="rect5"
              x="0"
              y="0"
              width={widthInsideEl}
              height={widthInsideEl}
              rx="2"
              ry="2"
            />
            <rect
              className="rect"
              id="rect6"
              x="0"
              y="0"
              width={widthInsideEl}
              height={widthInsideEl}
              rx="2"
              ry="2"
            />
            <rect
              className="rect"
              id="rect7"
              x="0"
              y="0"
              width={widthInsideEl}
              height={widthInsideEl}
              rx="2"
              ry="2"
            />
          </clipPath>
        </defs>
        <rect
          id="container"
          transform={`translate(${width / 2}) scale(0.707, 0.707) rotate(45)`}
          x="0"
          y="0"
          width={width}
          height={width}
          fill="url(#gradient)"
          clipPath="url(#rects)"
          style={{ WebkitTransformOriginY: "center" }}
        ></rect>
      </svg>
    </Wrap>
  );
};

export default Spinner;
