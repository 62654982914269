/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import {
  SMS_TIMING_OPTIONS,
  MESSAGE_TYPES,
  BEGIN_OPTION_DATE_SELECTION,
  AI_MODEL,
  AI_MODEL_FIELDS_IN_COMPANY,
  OPTIONS_DEFINITIONS,
  OPTIONS_TAB_IDS,
  MESSAGE_TYPES_OPTIONS,
  ATTEMPTS_OPTIONS,
  DURATION_OPTIONS,
  DURATION_OPTIONS_FOR_ADMIN,
  TONE_OPTIONS,
  BEGIN_OPTIONS,
  GOALS,
} from "../../constants";
import { isBullhornMarketingCampaignType } from "../../utils";
import getUserFromLocalStorage from "../../../../utils/getUserFromLocalStorage";
import CampaignMayExceedCreditsPopup from "./../CampaignMayExceedCreditsPopup";
import ConfirmLaunchPopup from "./../ConfirmLaunchPopup";
import EstimatedTokenCostCard from "./../EstimatedTokenCostCard";
import OptionsDefinitionPopup from "./../OptionsDefinitionPopup";
import {
  getCampaignTokensCost,
  getFilters,
} from "../../../../services/campaigns";
import { sendMessageToExtension } from "../../../../utils/postToExtension";
import StepButton from "../../../StepButton";
import {
  CRMS,
  FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS,
  FSC_STEP_TYPE,
  INTEGRATION_TYPE,
  PREPARED_CAMPAIGN_NAME_LOCAL_STORAGE_KEY,
  USER_ROLES,
} from "../../../../utils/constants";
import "react-datepicker/dist/react-datepicker.css";
import {
  getDataFromLocalStorage,
  getObjectFromLocalStorage,
  removeDataFromLocalStorage,
} from "../../../../api/localStorage";
import SuggestionPopup from "../../../SuggestionPopup";
import Tabs from "./Tabs";
import FirstTimeCampaignOnboarding from "../../../FirstTimeCampaignOnboarding";
import { Tooltip } from "react-tooltip";
import CustomSelect from "../../../CustomSelect";
import DatePicker from "react-datepicker";
import {
  GridItem,
  Label,
  ErrorMessage,
  CheckboxBlock,
  CheckboxLabel,
  SequenceTemplateField,
  DateSelect,
  InputTextarea,
} from "./styles";
import { buildSequenceTemplateStepName } from "../../../../utils/utils";
import { validateIntegrations } from "../../../../services/integrations";
import Loader from "../../../Loader";

const MAY_EXCEED_CREDITS_POPUP_CLOSED = 0;
const MAY_EXCEED_CREDITS_POPUP_OPENED_FROM_PREPARE = 1;
const MAY_EXCEED_CREDITS_POPUP_OPENED_FROM_LAUNCH = 2;

function ConfigureOptions({
  campaign,
  setCampaign,
  handleDecreaseStep,
  isCreatingCampaign,
  finishCreateCampaign,
  generateAll,
  crm,
  isEmailIntegration,
  isSmsIntegration,
}) {
  const navigate = useNavigate();
  const ref = useRef(null);

  const userInfo = getUserFromLocalStorage();
  const isAdminUser = userInfo?.role === USER_ROLES.ADMIN;

  const firstTimeCampaignOnboardingConfig = {
    ...FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.configureOptions,
    parentRef: ref,
  };

  const [errorSequenceTemplate, setErrorSequenceTemplate] = useState("");
  const [errorMessageType, setErrorMessageType] = useState("");
  const [errorMessageAttempts, setErrorMessageAttempts] = useState("");
  const [errorMessageBegin, setErrorMessageBegin] = useState("");
  const [errorMessageBeginDate, setErrorMessageBeginDate] = useState("");
  const [errorMessageDuration, setErrorMessageDuration] = useState("");
  const [errorMessageTone, setErrorMessageTone] = useState("");
  const [errorMessageSmsTiming, setErrorMessageSmsTiming] = useState("");
  const [isEstimatedTokenLoading, setIsEstimatedTokenLoading] = useState(true);
  const [isPreparing, setIsPreparing] = useState(false);
  const [optionsDefinitionPopupVisible, setOptionsDefinitionPopupVisible] =
    useState(true);
  const [estimatedToken, setEstimatedToken] = useState(0);
  const [creditsRemaining, setCreditsRemaining] = useState(0);

  const [mayExceedCreditsData, setMayExceedCreditsData] = useState(null);

  const [mayExceedCreditsPopupOpened, setMayExceedCreditsPopupOpened] =
    useState(MAY_EXCEED_CREDITS_POPUP_CLOSED);

  const [isConfirmLaunchPopupOpened, setIsConfirmLaunchPopupOpened] =
    useState(false);

  const [messageType, setMessageType] = useState(
    campaign.useSMS ? MESSAGE_TYPES.emailAndSms : MESSAGE_TYPES.email
  );

  const [instructions, setInstructions] = useState(
    campaign.additionalInstructions || ""
  );

  const [
    isUseContactNotesInCommunications,
    setIsUseContactNotesInCommunications,
  ] = useState(false);
  const [selectedOptionId, setSelectedOptionId] = useState(1);
  const [isOpenedOptionsDefinitionPopup, setIsOpenedOptionsDefinitionPopup] =
    useState(false);

  const [sequenceTemplateOptions, setSequenceTemplateOptions] = useState(null);
  const [isLoadingIntegration, setIsLoadingIntegration] = useState(false);
  const [noIntegrationListForPopup, setNoIntegrationListForPopup] =
    useState(null);

  const tabsRef = useRef(null);
  const optionsRef = useRef(null);
  const [isStickyHint, setIsStickyHint] = useState(false);

  useEffect(() => {
    if (campaign.isFullSequenceCampaign && !sequenceTemplateOptions) {
      const getOptions = async () => {
        const filtersData = await getFilters({
          filter: ["sequenceTemplates"],
        });
        if (filtersData.success) {
          const options = (filtersData.result?.sequenceTemplates || []).map(
            (o) => ({
              value: o.id,
              label: o.name,
              steps: o.steps,
            })
          );
          setSequenceTemplateOptions(options);
          if (
            campaign.sequenceTemplate &&
            !options.some((o) => o.value === campaign.sequenceTemplate)
          ) {
            handleSelectSequenceTemplate(null);
          }
        } else {
          setSequenceTemplateOptions([]);
          handleSelectSequenceTemplate(null);
        }
      };
      getOptions();
    }
  }, [campaign.isFullSequenceCampaign]);

  useEffect(() => {
    if (!optionsRef || !tabsRef) {
      return;
    }

    const handleScroll = () => {
      const containerNode = tabsRef.current.parentNode;

      const tabsBottomPosition = tabsRef.current.getBoundingClientRect().bottom;
      const containerTopPosition = containerNode.getBoundingClientRect().top;
      const offset = 7;

      if (tabsBottomPosition + offset <= containerTopPosition) {
        setIsStickyHint(true);
      } else {
        setIsStickyHint(false);
      }
    };

    optionsRef.current.addEventListener("scroll", handleScroll);

    return () => {
      optionsRef?.current?.removeEventListener("scroll", handleScroll);
    };
  }, [optionsRef]);

  const closeLaunchPopup = (e) => {
    e.preventDefault();
    setIsConfirmLaunchPopupOpened(false);
  };

  const prepareCampaign = (e) => {
    removeDataFromLocalStorage(PREPARED_CAMPAIGN_NAME_LOCAL_STORAGE_KEY, true);
    setIsPreparing(true);
    generateAll(e);
  };

  const startGenerateAll = async (e) => {
    e.preventDefault();

    if (campaign.begin === BEGIN_OPTION_DATE_SELECTION && !campaign.beginDate) {
      setErrorMessageBeginDate('Missed "Begin date"');
      return;
    }

    const message = await validateIntegrations(
      campaign.crmType,
      setIsLoadingIntegration,
      false
    );

    if (message) {
      setNoIntegrationListForPopup(message);
      return;
    }

    if (mayExceedCreditsData) {
      setMayExceedCreditsPopupOpened(
        MAY_EXCEED_CREDITS_POPUP_OPENED_FROM_PREPARE
      );
      return;
    }

    prepareCampaign(e);
  };

  const handleLaunch = async (e) => {
    e.preventDefault();

    if (!campaign.sequenceTemplate) {
      setErrorSequenceTemplate('Missed "Sequence template"');
      return;
    }

    const hasSmsStep = hasSmsStepInSequence(campaign.sequenceTemplate);
    const hasEmailStep = hasEmailStepInSequence(campaign.sequenceTemplate);

    const message = await validateIntegrations(
      campaign.crmType,
      setIsLoadingIntegration,
      hasEmailStep,
      hasSmsStep
    );

    if (message) {
      setNoIntegrationListForPopup(message);
      return;
    }

    if (mayExceedCreditsData) {
      setMayExceedCreditsPopupOpened(
        MAY_EXCEED_CREDITS_POPUP_OPENED_FROM_LAUNCH
      );
      return;
    }

    setIsConfirmLaunchPopupOpened(true);
  };

  const getIntegrationErrorPopup = () => {
    if (!noIntegrationListForPopup) {
      return null;
    }

    const isEmailInt = noIntegrationListForPopup.includes(
      INTEGRATION_TYPE.EMAIL
    );
    const isSmsInt = noIntegrationListForPopup.includes(INTEGRATION_TYPE.SMS);

    const onConfirm = () => {
      const extensionId = getDataFromLocalStorage("extensionId") || "";
      const extensionVer = getDataFromLocalStorage("extensionVersion") || "";
      navigate(
        `/settings?extensionId=${extensionId}&extensionVersion=${extensionVer}&${
          isEmailInt ? "isEmailInt" : isSmsInt ? "isSmsInt" : "isCrm"
        }=true`
      );
      sendMessageToExtension({
        message: "change-iframe-window-type",
        data: {
          iframeWindowType: "drawer",
        },
      });
    };

    if (!isEmailInt && !isSmsInt) {
      const error = noIntegrationListForPopup[0];
      const isExpired = error === "expired";
      const action = campaign.isFullSequenceCampaign ? "launch" : "create";
      return (
        <SuggestionPopup
          title={
            isExpired ? "Your integration has expired" : "Integration Error"
          }
          description={
            crm === CRMS.HUBSPOT
              ? `We are unable to ${action} a campaign as your HubSpot integration has ${error}.  Please reinstall the HubSpot app.`
              : `Looks like your integration ${error}.  To ${action} a campaign, please refresh your integration settings.`
          }
          onConfirm={onConfirm}
          onCancel={() => {
            sendMessageToExtension({
              message: "open-campaigns",
            });
          }}
          confirmBtnText="Let’s do it"
          cancelBtnText="I’ll do it later"
        />
      );
    }

    const labels = noIntegrationListForPopup.join(" and ");
    const ending = noIntegrationListForPopup.length > 1 ? "s" : "";
    return (
      <SuggestionPopup
        title={`${labels} Integration${ending} Needed`}
        description={`You cannot launch a campaign until you’ve integrated your ${labels} provider${ending}.  Would you like to do that now?`}
        onConfirm={onConfirm}
        onCancel={() => {
          setNoIntegrationListForPopup(null);
        }}
        confirmBtnText="Yes, take me there"
        cancelBtnText="Maybe later"
      />
    );
  };

  const handleCloseMayExceedCreditsPopup = (e) => {
    e.preventDefault();
    setMayExceedCreditsPopupOpened(MAY_EXCEED_CREDITS_POPUP_CLOSED);
  };

  const handleConfirmMayExceedCredits = (e) => {
    setMayExceedCreditsPopupOpened(MAY_EXCEED_CREDITS_POPUP_CLOSED);

    if (
      mayExceedCreditsPopupOpened ===
      MAY_EXCEED_CREDITS_POPUP_OPENED_FROM_PREPARE
    ) {
      prepareCampaign(e);
    } else {
      launchCampaign(e);
    }
  };

  const getFilteredSMSTimingOptions = useCallback(() => {
    const SMSTimingOptions = SMS_TIMING_OPTIONS.filter(
      (option) => option.value <= campaign.attempts
    );
    return SMSTimingOptions;
  }, [campaign.attempts]);

  const handleSelectChange = (value, fieldName) => {
    setErrorMessageAttempts("");
    setErrorMessageBegin("");
    setErrorMessageDuration("");
    setErrorMessageTone("");
    setErrorMessageSmsTiming("");
    if (fieldName === "beginDate") {
      setErrorMessageBeginDate("");
      setCampaign((campaign) => ({ ...campaign, [fieldName]: value }));
      return;
    }
    if (fieldName === "begin" && value !== BEGIN_OPTION_DATE_SELECTION) {
      setErrorMessageBeginDate("");
      setCampaign((campaign) => ({
        ...campaign,
        [fieldName]: value,
        beginDate: "",
      }));
      return;
    }
    setCampaign((campaign) => ({ ...campaign, [fieldName]: value }));
  };

  const fetchEstimatedTokenCost = async () => {
    setIsEstimatedTokenLoading(true);
    const {
      attempts,
      crmType,
      candidates,
      goal,
      autoReply,
      strategy,
      aiModel,
      additionalInstructions,
      jobDescription,
      jobDescriptionKey,
      sequenceTemplate,
    } = campaign;

    const filteredCandidates = candidates?.filter(
      (candidate) => !candidate.isInvalid
    );

    const data = {
      attempts,
      crmType,
      contacts: filteredCandidates || candidates,
      goal,
      autoReply,
      strategy,
      aiModel,
      additionalInstructions,
      sequenceTemplate,
    };
    if (sequenceTemplate) {
      delete data.attempts;
    }

    if (
      crm === CRMS.BULLHORN &&
      !isBullhornMarketingCampaignType(campaign.type)
    ) {
      const job_description = jobDescription[jobDescriptionKey];
      const job_pay_rate = campaign.payRate;

      data.crmData = {
        job_description,
        job_pay_rate,
      };
    } else {
      data.crmData = {
        campaign_type: campaign.type,
        campaign_details: campaign.details,
      };
    }

    const response = await getCampaignTokensCost(data);

    if (response.success !== undefined && !response.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: data.inProgress
            ? data.errorMessage
            : "Unfortunately, we were unable to calculate campaign cost",
        },
      });
      setIsEstimatedTokenLoading(false);
      return;
    }

    const estimatedTokenCount = Math.ceil(
      response.result.estimateTokens / 1000
    );
    const creditsRemaining = Math.round(
      response.result.creditsRemaining / 1000
    );

    setMayExceedCreditsData(
      creditsRemaining < estimatedTokenCount
        ? {
            estimatedTokenCount,
            creditsRemaining,
          }
        : null
    );

    setEstimatedToken(estimatedTokenCount);
    setCreditsRemaining(creditsRemaining);

    setCampaign((campaign) => ({
      ...campaign,
      estimatedToken: estimatedTokenCount,
      creditsRemaining: creditsRemaining,
    }));

    setIsEstimatedTokenLoading(false);
  };

  const changeMessageType = (value) => {
    setMessageType(value);
    if (
      (value === MESSAGE_TYPES.emailAndSms || value === MESSAGE_TYPES.sms) &&
      !isSmsIntegration
    ) {
      setErrorMessageType("You do not have an active SMS integration");
      return;
    }
    if (
      value === MESSAGE_TYPES.email ||
      ((value === MESSAGE_TYPES.emailAndSms || value === MESSAGE_TYPES.sms) &&
        isSmsIntegration)
    ) {
      setErrorMessageType("");
      setCampaign((campaign) => ({
        ...campaign,
        useSMS: value !== MESSAGE_TYPES.email,
        smsTiming: value === MESSAGE_TYPES.sms ? 1 : campaign.smsTiming,
        attempts: value === MESSAGE_TYPES.sms ? 1 : campaign.attempts,
      }));
    }
  };

  useEffect(() => {
    fetchEstimatedTokenCost();
  }, [
    campaign.attempts,
    campaign.candidates,
    campaign.goal,
    campaign.autoReply,
    campaign.strategy,
    campaign.details,
    campaign.type,
    campaign.aiModel,
    campaign.additionalInstructions,
    campaign.sequenceTemplate,
  ]);

  const handleChangeAddInstr = (e) => {
    setInstructions(e.target.value);
  };

  const handleBlurAddInstr = (e) => {
    const val = e.target.value.trim();
    setInstructions(val);
    handleSelectChange(val, "additionalInstructions");
  };

  const handleMouseOutAddInstr = (e) => {
    try {
      if (e.target === document.activeElement) {
        handleBlurAddInstr(e);
      }
    } catch {}
  };

  const companyInfo = getObjectFromLocalStorage("companyInfo");
  const aiModelOptions = Object.values(AI_MODEL)
    .filter((value) => campaign.aiModel || value !== AI_MODEL.DEFAULT)
    .map((value) => ({
      value,
      label: value,
      disabled:
        value !== AI_MODEL.DEFAULT &&
        !companyInfo?.company?.aiModel?.[AI_MODEL_FIELDS_IN_COMPANY[value]] &&
        !companyInfo?.company?.[AI_MODEL_FIELDS_IN_COMPANY[value]],
    }));
  const isDisabledAiModelField = !aiModelOptions.some((o) => !o.disabled);

  const handleChangeContactNotesInCom = (event) => {
    const useContactNotesInCommunications = event.target.checked;
    if (useContactNotesInCommunications) {
      setIsUseContactNotesInCommunications(true);
    } else {
      handleSelectChange(
        useContactNotesInCommunications,
        "useContactNotesInCommunications"
      );
    }
  };

  const handleChangeFullSequence = ({
    target: { checked: isFullSequenceCampaign },
  }) => {
    setCampaign((campaign) => ({
      ...campaign,
      isFullSequenceCampaign,
      sequenceTemplate: null,
    }));
    setErrorSequenceTemplate("");
  };

  const hasSmsStepInSequence = (sequenceTemplate) => {
    return sequenceTemplate
      ? sequenceTemplateOptions
          ?.find((o) => o.value === sequenceTemplate)
          ?.steps?.find((s) => s.type === FSC_STEP_TYPE.SMS)
      : null;
  };

  const hasEmailStepInSequence = (sequenceTemplate) => {
    return sequenceTemplate
      ? sequenceTemplateOptions
          ?.find((o) => o.value === sequenceTemplate)
          ?.steps?.find(
            (s) =>
              s.type === FSC_STEP_TYPE.EMAIL ||
              s.type === FSC_STEP_TYPE.MARKETING_EMAIL
          )
      : null;
  };

  const handleSelectSequenceTemplate = (sequenceTemplate) => {
    setCampaign((campaign) => ({ ...campaign, sequenceTemplate }));
    let errMsg = "";
    const hasSmsStep = hasSmsStepInSequence(sequenceTemplate);
    const hasEmailStep = hasEmailStepInSequence(sequenceTemplate);
    const noIntegrations = [];
    if (hasEmailStep && !isEmailIntegration) {
      noIntegrations.push("Email");
    }
    if (hasSmsStep && !isSmsIntegration) {
      noIntegrations.push("SMS");
    }
    if (noIntegrations.length) {
      errMsg = `You do not have an active ${noIntegrations.join(
        " and "
      )} integration${noIntegrations.length > 1 ? "s" : ""}`;
    }
    setErrorSequenceTemplate(errMsg);
  };

  const handleSelectOption = (optionId) => {
    setSelectedOptionId(optionId);
    if (optionsDefinitionPopupVisible) {
      setIsOpenedOptionsDefinitionPopup((prevState) => {
        if (prevState === true) {
          return false;
        }
      });
    }
  };

  const launchCampaign = (e) => {
    removeDataFromLocalStorage(PREPARED_CAMPAIGN_NAME_LOCAL_STORAGE_KEY, true);
    setIsPreparing(true);
    setIsConfirmLaunchPopupOpened(false);
    finishCreateCampaign(e);
  };

  const fields = [
    {
      tabId: OPTIONS_TAB_IDS.GeneralOptions,
      label: "Full Sequence Campaign",
      get: () => {
        return (
          <CheckboxBlock>
            <input
              id="isFullSequenceCampaign"
              type="checkbox"
              checked={campaign.isFullSequenceCampaign}
              value={campaign.isFullSequenceCampaign}
              onChange={handleChangeFullSequence}
            />
            <label htmlFor="isFullSequenceCampaign" className="toggle"></label>
            <CheckboxLabel $checked={campaign.isFullSequenceCampaign}>
              {campaign.isFullSequenceCampaign ? "On" : "Off"}
            </CheckboxLabel>
          </CheckboxBlock>
        );
      },
      isAllowed: () => {
        return isAdminUser;
      },
    },
    {
      tabId: OPTIONS_TAB_IDS.GeneralOptions,
      label: "Sequence template",
      get: () => {
        return (
          <>
            <SequenceTemplateField>
              <CustomSelect
                disabled={!sequenceTemplateOptions}
                options={sequenceTemplateOptions || []}
                title="Select..."
                width="100%"
                selected={campaign.sequenceTemplate}
                onSelect={handleSelectSequenceTemplate}
              />
              <div
                style={
                  campaign.sequenceTemplate ? null : { visibility: "hidden" }
                }
                data-tooltip-id={
                  campaign.sequenceTemplate ? "sequence-template-tooltip" : null
                }
                data-tooltip-content={
                  campaign.sequenceTemplate
                    ? sequenceTemplateOptions
                        ?.find((o) => o.value === campaign.sequenceTemplate)
                        ?.steps?.map((step) =>
                          buildSequenceTemplateStepName(step, true)
                        )
                        .join("\n")
                    : null
                }
              >
                <img src="/images/info-icon.png" alt="info" width="18" />
              </div>
              <Tooltip
                id="sequence-template-tooltip"
                className="custom-tooltip ws-pre-wrap"
                place="right"
              />
            </SequenceTemplateField>
            {errorSequenceTemplate !== "" && (
              <ErrorMessage>{errorSequenceTemplate}</ErrorMessage>
            )}
          </>
        );
      },
      isAllowed: () => {
        return campaign.isFullSequenceCampaign;
      },
      containerProps: { $plusWidth: "30px" },
    },
    {
      tabId: OPTIONS_TAB_IDS.GeneralOptions,
      label: "Message types",
      get: () => {
        return (
          <>
            <CustomSelect
              options={MESSAGE_TYPES_OPTIONS}
              title="Message types"
              width="100%"
              selected={messageType}
              onSelect={changeMessageType}
            />
            {errorMessageType !== "" && (
              <ErrorMessage>{errorMessageType}</ErrorMessage>
            )}
          </>
        );
      },
      isAllowed: () => {
        return !campaign.isFullSequenceCampaign;
      },
    },
    {
      tabId: OPTIONS_TAB_IDS.GeneralOptions,
      label: "Follow-ups",
      get: () => {
        return (
          <>
            <CustomSelect
              options={ATTEMPTS_OPTIONS}
              title="Follow-ups"
              width="100%"
              selected={campaign.attempts - 1}
              onSelect={(value) => handleSelectChange(value + 1, "attempts")}
            />
            {errorMessageAttempts !== "" && (
              <ErrorMessage>{errorMessageAttempts}</ErrorMessage>
            )}
          </>
        );
      },
      isAllowed: () => {
        return (
          !campaign.isFullSequenceCampaign && messageType !== MESSAGE_TYPES.sms
        );
      },
    },
    {
      tabId: OPTIONS_TAB_IDS.GeneralOptions,
      label: "Duration",
      get: () => {
        return (
          <>
            <CustomSelect
              options={
                isAdminUser
                  ? [...DURATION_OPTIONS, ...DURATION_OPTIONS_FOR_ADMIN]
                  : DURATION_OPTIONS
              }
              title="Duration"
              width="100%"
              selected={campaign.duration}
              onSelect={(value) => handleSelectChange(value, "duration")}
            />
            {errorMessageDuration !== "" && (
              <ErrorMessage>{errorMessageDuration}</ErrorMessage>
            )}
          </>
        );
      },
      isAllowed: () => {
        return !campaign.isFullSequenceCampaign;
      },
    },
    {
      tabId: OPTIONS_TAB_IDS.GeneralOptions,
      label: "AI Model",
      get: () => {
        return (
          <>
            <div
              data-tooltip-id={
                isDisabledAiModelField ? "no-ai-model-tooltip" : null
              }
              data-tooltip-content={
                isAdminUser
                  ? `Please add ${Object.values(AI_MODEL)
                      .filter((v) => v !== AI_MODEL.DEFAULT)
                      .join("/")} models for the company in the admin settings`
                  : `To enable additional models, please contact support at help@salesstream.ai`
              }
            >
              <CustomSelect
                disabled={isDisabledAiModelField}
                options={aiModelOptions}
                title={AI_MODEL.DEFAULT}
                width="100%"
                selected={campaign.aiModel}
                onSelect={(value) =>
                  handleSelectChange(
                    value && value !== AI_MODEL.DEFAULT ? value : "",
                    "aiModel"
                  )
                }
              />
            </div>
            <Tooltip
              id="no-ai-model-tooltip"
              className="custom-tooltip custom-tooltip_custom-width"
              place="right"
            />
          </>
        );
      },
      isAllowed: () => {
        return true;
      },
    },
    {
      tabId: OPTIONS_TAB_IDS.GeneralOptions,
      label: "Tone",
      get: () => {
        return (
          <>
            <CustomSelect
              options={TONE_OPTIONS}
              title="Tone"
              width="100%"
              selected={campaign.tone}
              onSelect={(value) => handleSelectChange(value, "tone")}
            />
            {errorMessageTone !== "" && (
              <ErrorMessage>{errorMessageTone}</ErrorMessage>
            )}
          </>
        );
      },
      isAllowed: () => {
        return true;
      },
    },
    {
      tabId: OPTIONS_TAB_IDS.AdditionalOptions,
      label: "Begin",
      get: () => {
        return (
          <>
            <CustomSelect
              options={BEGIN_OPTIONS}
              title="Begin"
              width="100%"
              selected={campaign.begin}
              onSelect={(value) => handleSelectChange(value, "begin")}
            />
            {errorMessageBegin !== "" && (
              <ErrorMessage>{errorMessageBegin}</ErrorMessage>
            )}
          </>
        );
      },
      isAllowed: () => {
        return !campaign.isFullSequenceCampaign;
      },
    },
    {
      tabId: OPTIONS_TAB_IDS.AdditionalOptions,
      label: "Begin date",
      get: () => {
        return (
          <>
            <DateSelect>
              <DatePicker
                placeholderText="MM/dd/yyyy"
                dateFormat="MM/dd/yyyy"
                selected={
                  campaign.beginDate ? new Date(campaign.beginDate) : null
                }
                onChange={(date) =>
                  handleSelectChange(date?.toISOString() || "", "beginDate")
                }
                minDate={new Date()}
                isClearable={true}
                clearButtonTitle="Clear"
                todayButton="Today"
              />
            </DateSelect>
            {errorMessageBeginDate !== "" && (
              <ErrorMessage>{errorMessageBeginDate}</ErrorMessage>
            )}
          </>
        );
      },
      isAllowed: () => {
        return (
          !campaign.isFullSequenceCampaign &&
          campaign.begin === BEGIN_OPTION_DATE_SELECTION
        );
      },
    },
    {
      tabId: OPTIONS_TAB_IDS.AdditionalOptions,
      label: "SMS Timing",
      get: () => {
        return (
          <>
            <CustomSelect
              options={getFilteredSMSTimingOptions()}
              title="SMS Timing"
              width="100%"
              selected={campaign.smsTiming}
              onSelect={(value) => handleSelectChange(value, "smsTiming")}
            />
            {errorMessageSmsTiming !== "" && (
              <ErrorMessage>{errorMessageSmsTiming}</ErrorMessage>
            )}
          </>
        );
      },
      isAllowed: () => {
        return (
          !campaign.isFullSequenceCampaign &&
          campaign.useSMS &&
          messageType !== MESSAGE_TYPES.sms
        );
      },
    },
    {
      tabId: OPTIONS_TAB_IDS.AdditionalOptions,
      label: "Send follow-ups as replies",
      get: () => {
        return (
          <CheckboxBlock>
            <input
              id="followUpsAsRepliesCheckbox"
              type="checkbox"
              checked={campaign.isFollowUpsAsReplies}
              value={campaign.isFollowUpsAsReplies}
              onChange={(event) =>
                handleSelectChange(event.target.checked, "isFollowUpsAsReplies")
              }
            />
            <label
              htmlFor="followUpsAsRepliesCheckbox"
              className="toggle"
            ></label>
            <CheckboxLabel $checked={campaign.isFollowUpsAsReplies}>
              {campaign.isFollowUpsAsReplies ? "On" : "Off"}
            </CheckboxLabel>
          </CheckboxBlock>
        );
      },
      isAllowed: () => {
        return true;
      },
    },
    {
      tabId: OPTIONS_TAB_IDS.AdditionalOptions,
      label: "Use contact notes in communications",
      get: () => {
        return (
          <CheckboxBlock>
            <input
              id="useContactNotesInCommunicationsCheckbox"
              type="checkbox"
              checked={campaign.useContactNotesInCommunications}
              value={campaign.useContactNotesInCommunications}
              onChange={handleChangeContactNotesInCom}
            />
            <label
              htmlFor="useContactNotesInCommunicationsCheckbox"
              className="toggle"
            ></label>
            <CheckboxLabel $checked={campaign.useContactNotesInCommunications}>
              {campaign.useContactNotesInCommunications ? "On" : "Off"}
            </CheckboxLabel>
          </CheckboxBlock>
        );
      },
      isAllowed: () => {
        return crm !== CRMS.GOOGLE_SHEETS;
      },
    },
    {
      tabId: OPTIONS_TAB_IDS.AdditionalOptions,
      label: "Auto Reply",
      get: () => {
        return (
          <CheckboxBlock>
            <input
              id="autoReplyCheckbox"
              type="checkbox"
              checked={campaign.autoReply}
              value={campaign.autoReply}
              onChange={(event) =>
                handleSelectChange(event.target.checked, "autoReply")
              }
            />
            <label htmlFor="autoReplyCheckbox" className="toggle"></label>
            <CheckboxLabel $checked={campaign.autoReply}>
              {campaign.autoReply ? "On" : "Off"}
            </CheckboxLabel>
          </CheckboxBlock>
        );
      },
      isAllowed: () => {
        return (
          !campaign.isFullSequenceCampaign &&
          isAdminUser &&
          !!isEmailIntegration &&
          campaign.goal !== GOALS.emailReply
        );
      },
    },
    {
      tabId: OPTIONS_TAB_IDS.AdditionalOptions,
      label: "Additional Instructions",
      get: () => {
        return (
          <InputTextarea
            id="instructions"
            placeholder="Make the messages around 50 words long…"
            value={instructions}
            onChange={handleChangeAddInstr}
            onBlur={handleBlurAddInstr}
            onMouseOut={handleMouseOutAddInstr}
            autoComplete="off"
          />
        );
      },
      isAllowed: () => {
        return true;
      },
    },
  ].filter((f) => f.tabId === selectedOptionId && f.isAllowed());

  const definitions = OPTIONS_DEFINITIONS.filter((d) =>
    fields.some((f) => f.label === d.title)
  );

  return (
    <>
      {(isLoadingIntegration || isPreparing) && <Loader zIndex={11} />}
      <Section ref={ref}>
        <FirstTimeCampaignOnboarding
          config={firstTimeCampaignOnboardingConfig}
        />
        {mayExceedCreditsPopupOpened !== MAY_EXCEED_CREDITS_POPUP_CLOSED && (
          <CampaignMayExceedCreditsPopup
            values={mayExceedCreditsData}
            onConfirm={handleConfirmMayExceedCredits}
            onCancel={handleCloseMayExceedCreditsPopup}
          />
        )}
        {isConfirmLaunchPopupOpened && (
          <ConfirmLaunchPopup
            isFullSequenceCampaign={campaign.isFullSequenceCampaign}
            cancelLaunch={closeLaunchPopup}
            confirmLaunch={launchCampaign}
          />
        )}
        {isUseContactNotesInCommunications &&
          !campaign.useContactNotesInCommunications && (
            <SuggestionPopup
              title="We thought you should know!"
              description="Using contact notes in campaigns may greatly increase AI Credit usage."
              onConfirm={() => {
                setIsUseContactNotesInCommunications(false);
                handleSelectChange(
                  !campaign.useContactNotesInCommunications,
                  "useContactNotesInCommunications"
                );
              }}
              onCancel={() => setIsUseContactNotesInCommunications(false)}
              confirmBtnText="Got it!"
              cancelBtnText="Maybe later"
              styles={{
                titleStyle: {
                  fontColor: "#ff0000",
                },
              }}
            />
          )}
        {getIntegrationErrorPopup()}
        <Title>Campaign Options</Title>
        <OptionsContainer>
          <OptionsItems $isRelative={!isStickyHint} ref={optionsRef}>
            {optionsDefinitionPopupVisible && (
              <OptionsDefinitionPopup
                handleClose={() => setOptionsDefinitionPopupVisible(false)}
                isOpened={isOpenedOptionsDefinitionPopup}
                setIsOpened={setIsOpenedOptionsDefinitionPopup}
                definitions={definitions}
                isSticky={isStickyHint}
              />
            )}
            <Tabs
              ref={tabsRef}
              selectedOptionId={selectedOptionId}
              handleSelectOption={handleSelectOption}
            />
            {fields.map((field) => (
              <GridItem key={field.label} {...field.containerProps}>
                <Label>{field.label}:</Label>
                {field.get()}
              </GridItem>
            ))}
          </OptionsItems>
        </OptionsContainer>
      </Section>
      <EstimatedTokenCostCard
        isLoading={isEstimatedTokenLoading}
        estimatedToken={estimatedToken}
        creditsRemaining={creditsRemaining}
      />
      <ButtonGroup>
        {!isCreatingCampaign && (
          <StepButton onClick={handleDecreaseStep} isNext={false} />
        )}
        <LaunchCampaignBtn
          onClick={
            campaign.isFullSequenceCampaign ? handleLaunch : startGenerateAll
          }
          $width={"200px"}
          type="submit"
          disabled={
            campaign.candidatesLength === 0 ||
            isCreatingCampaign ||
            isPreparing ||
            isEstimatedTokenLoading ||
            isLoadingIntegration ||
            errorSequenceTemplate !== "" ||
            errorMessageBeginDate !== "" ||
            errorMessageType !== ""
          }
        >
          {campaign.isFullSequenceCampaign
            ? "Launch Campaign"
            : "Prepare Campaign"}
        </LaunchCampaignBtn>
      </ButtonGroup>
    </>
  );
}

export default ConfigureOptions;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const Section = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  font-size: 18px;
`;

const ButtonGroup = styled.div`
  grid-row-start: 2;
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  align-items: end;
`;

const LaunchCampaignBtn = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: white;
  border-radius: 5px;
  height: 40px;
  width: ${({ $width }) => ($width ? $width : "120px")};
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? "rgb(71,17,104,0.75)" : theme.colors.saturated_purple};
  background-color: ${({ disabled, theme }) =>
    disabled ? "rgb(71,17,104,0.75)" : theme.colors.saturated_purple};
  font-weight: 700;
  font-size: 12px;
  font-family: "AlbertSansExtraBold", sans-serif;
`;

const OptionsContainer = styled.div`
  position: relative;
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px #0000001a;
  padding: 10px 0;
  height: calc(100vh - 196px);
`;

const OptionsItems = styled.div`
  position: ${({ $isRelative }) => ($isRelative ? "relative" : "unset")};
  padding-bottom: 20px;
  height: 100%;
  overflow: auto;
`;
